import { useEffect } from 'react'
import { Redirect, useParams } from 'react-router-dom'

import { DEFAULT_DOCUMENT_TITLE, ROUTE_PATHS } from '../../../constants'

export default function ProductCategoryPage() {
  const { category: categoryParam } = useParams<{ category: string }>()

  const category = decodeURIComponent(categoryParam)
  useEffect(() => {
    document.title = `${category ?? 'Products'} | Prodigi`
    return () => {
      document.title = DEFAULT_DOCUMENT_TITLE
    }
  })

  return <Redirect to={ROUTE_PATHS.PRODUCT_CATALOGUE} />
}

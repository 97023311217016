import { motion } from 'motion/react'

import IconCross from '../IconCross'

export type FilterDataType = { value: string; onClearFilter: () => void }
export type AppliedFiltersType = Record<string, FilterDataType>

interface FilterPillPropsInterface {
  filterData: FilterDataType
  filterName: string
}

export default function FilterPill({ filterName, filterData }: FilterPillPropsInterface) {
  return (
    <motion.li
      animate={{ opacity: 1, scale: 1 }}
      className="flex min-w-fit"
      exit={{ opacity: 0, scale: 0 }}
      initial={{ opacity: 0, scale: 0 }}
      layout
    >
      <span className="flex items-center rounded-l-lg bg-gray-200 px-3 py-1">
        <span>{filterName}:&nbsp;</span>
        <span className="font-medium">{filterData.value}</span>
      </span>
      <motion.button
        className="ml-1 rounded-r-lg bg-gray-200 p-3"
        variants={{
          tap: {
            scale: 0.8
          }
        }}
        whileHover={'hover'}
        whileTap={'tap'}
        onClick={filterData.onClearFilter}
      >
        <IconCross
          className="h-5 w-5"
          variants={{
            hover: {
              scale: 1.25
            }
          }}
        />
        <span className="sr-only">Clear applied filter {`${filterName}: ${filterData.value}`}</span>
      </motion.button>
    </motion.li>
  )
}

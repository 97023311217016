import { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid'

import { SortOrdersByOption } from '../../../enums'
import { DEFAULT_ORDERS_SORT_ORDER, QUERY_PARAMS, ROUTE_PATHS } from '../../../constants'
import { DropdownMenu, DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuTrigger } from '../../DropdownMenu'

/* -------------------------------------------------------------------------- */
/*                               Main component                               */
/* -------------------------------------------------------------------------- */

export function OrdersListActionSort() {
  const location = useLocation()
  const history = useHistory()
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search])

  const selectedSortParam = searchParams.get(QUERY_PARAMS.ORDERS.SORT)
  const selectedSortValue = useMemo(() => selectedSortParam || DEFAULT_ORDERS_SORT_ORDER, [selectedSortParam])
  const [selectedSortOption, setSelectedSortOption] = useState(selectedSortValue)

  // Ensures URL changes are captured when using browser navigation buttons
  useEffect(() => {
    setSelectedSortOption(selectedSortValue)
  }, [selectedSortValue, searchParams])

  const updateSelectedSortOption = (selected: SortOrdersByOption) => {
    setSelectedSortOption(selected)
    const newSearchParams = new URLSearchParams(searchParams)
    newSearchParams.set(QUERY_PARAMS.ORDERS.SORT, selected)
    const newOrdersUrl = `${ROUTE_PATHS.ORDERS}?${newSearchParams.toString()}`
    history.push(newOrdersUrl)
  }

  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger data-test="orders-list-actions__sort-trigger">
        <ChevronUpDownIcon aria-hidden="true" className="mr-2 h-7 w-7 text-gray-400" />
        {selectedSortOption === SortOrdersByOption.NEWEST ? 'Newest first' : 'Oldest first'}
      </DropdownMenuTrigger>

      <DropdownMenuContent align="end" data-test="orders-list-actions__sort-options">
        <DropdownMenuCheckboxItem
          data-test="orders-list-actions__sort-option"
          data-test-value={`orders-list-actions__sort-option-${SortOrdersByOption.NEWEST}`}
          checked={selectedSortOption === SortOrdersByOption.NEWEST}
          onSelect={() => {
            window.analytics.track('Clicked Sort By Newest First')
            updateSelectedSortOption(SortOrdersByOption.NEWEST)
          }}
        >
          Newest first
        </DropdownMenuCheckboxItem>
        <DropdownMenuCheckboxItem
          data-test="orders-list-actions__sort-option"
          data-test-value={`orders-list-actions__sort-option-${SortOrdersByOption.OLDEST}`}
          checked={selectedSortOption === SortOrdersByOption.OLDEST}
          onSelect={() => {
            window.analytics.track('Clicked Sort By Oldest First')
            updateSelectedSortOption(SortOrdersByOption.OLDEST)
          }}
        >
          Oldest first
        </DropdownMenuCheckboxItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

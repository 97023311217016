import clsx from 'clsx'
import { motion } from 'motion/react'
import { twMerge } from 'tailwind-merge'
import * as SwitchPrimitive from '@radix-ui/react-switch'

interface PropTypes {
  className?: string
  checked: boolean
  disabled?: boolean
  required?: boolean
  onChange: (isChecked: boolean) => void
}

const switchVariants = {
  checked: { x: 17 },
  unChecked: { x: 1 }
}

export default function Switch({ className = '', checked, disabled = false, required = false, onChange }: PropTypes) {
  return (
    <SwitchPrimitive.Root
      checked={checked}
      className={twMerge(
        clsx(
          'relative h-[20px] w-[36px] rounded-[9999px] transition-colors disabled:cursor-not-allowed disabled:opacity-50',
          checked ? 'bg-purple-600' : 'bg-gray-300'
        ),
        className
      )}
      disabled={disabled}
      required={required}
      onCheckedChange={onChange}
    >
      <SwitchPrimitive.Thumb asChild className="h-[18px] w-[18px] rounded-full bg-white">
        <motion.div
          initial={false}
          animate={checked ? 'checked' : 'unChecked'}
          transition={{ type: 'tween', duration: 0.15 }}
          variants={switchVariants}
        />
      </SwitchPrimitive.Thumb>
    </SwitchPrimitive.Root>
  )
}

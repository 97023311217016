import { useEffect, useMemo } from 'react'
import { motion } from 'motion/react'
import { useLocation, useHistory } from 'react-router'

import { OrderFilterHeader, OrdersListActionStatus } from '.'
import { OnChangeFilterType } from '../types'
import { QUERY_PARAMS, ROUTE_PATHS } from '../../../constants'

export function OrderFilterStatus({ onChangeFilter }: { onChangeFilter: OnChangeFilterType }) {
  const { search } = useLocation()
  const history = useHistory()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  function handleClear() {
    searchParams.delete(QUERY_PARAMS.ORDERS.STATUS)
    history.push(`${ROUTE_PATHS.ORDERS}?${searchParams.toString()}`)
  }

  useEffect(() => {
    window.analytics.track('Clicked Filter By Status')
  }, [])

  return (
    <motion.div
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 5 }}
      initial={{ opacity: 0, x: 5 }}
      key="status"
      transition={{ type: 'tween', duration: 0.1 }}
    >
      <OrderFilterHeader
        showClearAction={searchParams.has(QUERY_PARAMS.ORDERS.STATUS)}
        title="Status"
        onBack={() => onChangeFilter(null)}
        onClear={handleClear}
      />
      <OrdersListActionStatus />
    </motion.div>
  )
}

import { motion, Variants } from 'motion/react'

interface IconCrossPropsInterface {
  className?: string
  style?: React.CSSProperties
  variants?: Variants
}

export default function IconCross({ className = '', style, variants }: IconCrossPropsInterface) {
  return (
    <motion.svg
      className={className}
      fill="currentColor"
      style={{ originX: '50%', originY: '50%', ...style }}
      variants={variants}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </motion.svg>
  )
}

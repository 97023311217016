import { useSWRConfig } from 'swr'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { CogIcon, ArrowRightStartOnRectangleIcon, UserIcon } from '@heroicons/react/24/outline'

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '../../DropdownMenu'
import { cn, isProUser } from '../../../helpers'
import { logOut } from '../../../../actions/auth'
import { useSplitToggle, useUser } from '../../../hooks'
import { SubscriptionTierType } from '../../../interfaces'
import { APP_ENVIRONMENTS, ROUTE_PATHS } from '../../../constants'
import { FEATURE_NAMES } from '../../../../split-io/feature-names'

export function AccountNavItem({ className = '', isModal = false }: { className?: string; isModal?: boolean }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const { cache } = useSWRConfig()
  const { user } = useUser()
  const { splitIsOn } = useSplitToggle({ toggle: FEATURE_NAMES.DASHBOARD_PRODIGI_PRO })

  function handleLogout() {
    // SWR v1.3.0 supports this as per the docs but is not typed
    // prettier-ignore
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (cache as any)?.clear?.()
    // TODO: v2: Remove v1 logout when updated
    dispatch(logOut())
  }

  return (
    <DropdownMenu modal={isModal}>
      <DropdownMenuTrigger className={cn('border-none px-6 py-6', className)}>
        <UserIcon className="h-9 w-9" />
        <span className="sr-only">Account options</span>
      </DropdownMenuTrigger>

      <DropdownMenuContent className="mr-1 max-h-96 w-[240px]">
        {user && (
          <>
            <DropdownMenuGroup className="bg-gray-50 px-6 py-4">
              <span className="flex items-center justify-between gap-2">
                <span className="truncate font-medium">{user.fullName}</span>
                {splitIsOn && (
                  <span
                    className={cn(
                      'text-xs font-bold uppercase',
                      getSubscriptionTierThemeColor(user.merchantSubscriptionSettings.subscriptionTier)
                    )}
                  >
                    {isProUser(user.merchantSubscriptionSettings.subscriptionTier)
                      ? 'Pro'
                      : user.merchantSubscriptionSettings.subscriptionTier}
                  </span>
                )}
              </span>
              {Boolean(user.company.name) && <span className="truncate text-gray-600">{user.company.name}</span>}
            </DropdownMenuGroup>
          </>
        )}
        <DropdownMenuGroup aria-label="Account options">
          <DropdownMenuItem
            className="border-t border-gray-200 px-4 py-5"
            startIcon={<CogIcon className="h-8 w-8 text-gray-600" aria-hidden="true" />}
            onSelect={() => {
              const settingsPath =
                process.env.REACT_APP_ENV === APP_ENVIRONMENTS.SANDBOX
                  ? ROUTE_PATHS.SETTINGS.API
                  : ROUTE_PATHS.SETTINGS.ACCOUNT
              history.push(settingsPath)
            }}
          >
            Settings
          </DropdownMenuItem>
          <DropdownMenuItem
            className="border-t border-gray-200 px-4 py-5"
            startIcon={<ArrowRightStartOnRectangleIcon className="h-8 w-8 text-gray-600" aria-hidden="true" />}
            onSelect={() => {
              handleLogout()
            }}
          >
            Log out
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

function getSubscriptionTierThemeColor(subscriptionTier: SubscriptionTierType) {
  switch (subscriptionTier) {
    case 'Core':
      return 'text-purple-600'
    case 'Enterprise':
      return 'text-cyan-500'
    case 'ProMonthlyBilling':
    case 'ProYearlyBilling':
      return 'text-status-orange'
    default:
      if (process.env.REACT_APP_ENV === APP_ENVIRONMENTS.TEST) {
        console.warn(`Unhandled subscription tier ${subscriptionTier}. Add a new case to fix this.`)
      }
      return 'text-gray-600'
  }
}

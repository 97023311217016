import toast from 'react-hot-toast'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Squares2X2Icon, ArchiveBoxIcon } from '@heroicons/react/24/outline'
import { EllipsisHorizontalIcon, PhotoIcon } from '@heroicons/react/20/solid'

import {
  copyVariantProductDetails,
  copyVariantImageDetails,
  copyVariantAllDetails,
  copyWillOverwriteExistingData
} from '../helpers'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '../../DropdownMenu'
import { CopyDetailsModal } from '.'
import { createErrorToast } from '../../Toast'
import { CopyDetailsValueType } from '../types'
import { useMerchantService } from '../../../hooks'
import { isNoImageProduct } from '../../../helpers'
import { APP_ENVIRONMENTS } from '../../../constants'
import OverlayLoadingSpinner from '../../OverlayLoadingSpinner'
import { saveVariantsData } from '../helpers/saveVariantsData.helper'
import { SALES_CHANANEL_PRODUCT_TOAST_IDS } from '../constants/toastIds.const'
import { useSalesChannelProduct } from '../../../hooks/useSalesChannelProduct.hook'
import { VariantDataInterface, VariantsSkuDataType } from '../SalesChannelProduct.component'
import { FetchErrorInterface, OMSErrorResponseInterface, StatusType } from '../../../interfaces'

interface CopyDetailsPropsInterface {
  allVariantsData: Record<string, VariantDataInterface>
  variantDataToCopy: VariantDataInterface
  variantsSkuData: VariantsSkuDataType
}

export function CopyDetails({ allVariantsData, variantDataToCopy, variantsSkuData }: CopyDetailsPropsInterface) {
  const [selectedCopyOption, setSelectedCopyOption] = useState<CopyDetailsValueType | null>(null)
  const [saveDataStatus, setSaveDataStatus] = useState<StatusType>('idle')
  const { merchantDetails } = useMerchantService()
  const { salesChannelId, productId } = useParams<{ salesChannelId: string; productId: string }>()
  const { mutateSalesChannelProduct } = useSalesChannelProduct({ productId, salesChannelId })

  useEffect(() => {
    return () => {
      toast.dismiss(SALES_CHANANEL_PRODUCT_TOAST_IDS.ERROR)
    }
  }, [])

  async function handleSaveCopiedData(newVariantsData: Record<string, VariantDataInterface>) {
    setSaveDataStatus('loading')
    toast.dismiss(SALES_CHANANEL_PRODUCT_TOAST_IDS.ERROR)

    try {
      await saveVariantsData({
        merchantId: merchantDetails?.id ?? null,
        newVariantsData,
        productId,
        salesChannelId
      })
      await mutateSalesChannelProduct()
      setSaveDataStatus('success')
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<OMSErrorResponseInterface>
      const errorCode = `${errorResponse.responseBodyJson?.traceParent ?? '0'}-${errorResponse.status ?? '0'}`
      createErrorToast({ id: SALES_CHANANEL_PRODUCT_TOAST_IDS.ERROR, errorCode, heading: 'Failed to update variants' })
      setSaveDataStatus('error')
    }
  }

  async function handleCopy(copyOption: CopyDetailsValueType) {
    switch (copyOption) {
      case 'copy-all': {
        window.analytics.track('Sales channels - copy product and image to all')
        const newVariantsData = copyVariantAllDetails({ allVariantsData, variantDataToCopy })
        await handleSaveCopiedData(newVariantsData)
        return
      }
      case 'copy-image': {
        window.analytics.track('Sales channels - copy image to all')
        const newVariantsData = copyVariantImageDetails({ allVariantsData, variantDataToCopy, variantsSkuData })
        await handleSaveCopiedData(newVariantsData)
        return
      }
      case 'copy-product': {
        window.analytics.track('Sales channels - copy product to all')
        const newVariantsData = copyVariantProductDetails({
          allVariantsData,
          variantDataToCopy,
          variantsSkuData
        })
        await handleSaveCopiedData(newVariantsData)
        return
      }
      default: {
        const copyUnhandledCase: never = copyOption
        if (process.env.REACT_APP_ENV === APP_ENVIRONMENTS.TEST) {
          console.warn(`Unable to copy details for type ${copyUnhandledCase}. Add a new case to fix this.`)
        }
        return null
      }
    }
  }

  function onSelection(copyOption: CopyDetailsValueType) {
    const willOverwrite = copyWillOverwriteExistingData({ allVariantsData, copyType: copyOption, variantDataToCopy })

    if (willOverwrite) {
      setSelectedCopyOption(copyOption)
    } else {
      setSelectedCopyOption(null)
      handleCopy(copyOption)
    }
  }

  const hasArtwork =
    variantDataToCopy.artworkData &&
    Boolean(variantDataToCopy.artworkData) &&
    Object.values(variantDataToCopy.artworkData).some((value) => Boolean(value))

  const noArtworkProduct = Boolean(
    variantDataToCopy.selectedProductData && isNoImageProduct(variantDataToCopy.selectedProductData?.productType)
  )

  const otherVariantsHaveProducts = Object.entries(allVariantsData).some(
    ([variantId, variantData]) =>
      variantId !== variantDataToCopy.id &&
      variantData.isFulfilled &&
      variantData.selectedProductData &&
      Boolean(variantData.selectedProductData)
  )

  const modalWillBeShown = Boolean(
    selectedCopyOption &&
      copyWillOverwriteExistingData({ allVariantsData, copyType: selectedCopyOption, variantDataToCopy })
  )

  return (
    <div>
      <DropdownMenu modal={false}>
        <DropdownMenuTrigger className="aspect-square border-gray-300">
          <EllipsisHorizontalIcon className="h-6 w-6 text-gray-500" />
          <span className="sr-only">Options</span>
        </DropdownMenuTrigger>

        <DropdownMenuContent align="end">
          <DropdownMenuItem
            className="pb-4 pl-8 pr-8 pt-4"
            disabled={!hasArtwork || noArtworkProduct}
            startIcon={<Squares2X2Icon className="mr-2 h-8 w-8 text-gray-600" aria-hidden="true" />}
            onSelect={() => {
              onSelection('copy-all')
            }}
          >
            Copy image & product to all
          </DropdownMenuItem>
          <DropdownMenuSeparator className="mx-8" />
          <DropdownMenuItem
            className="pb-4 pl-8 pr-8 pt-4"
            disabled={!hasArtwork || noArtworkProduct || !otherVariantsHaveProducts}
            startIcon={<PhotoIcon className="mr-2 h-7 w-7 text-gray-600" aria-hidden="true" />}
            onSelect={() => {
              onSelection('copy-image')
            }}
          >
            Copy image to all
          </DropdownMenuItem>
          <DropdownMenuItem
            className="pb-4 pl-8 pr-8 pt-4"
            startIcon={<ArchiveBoxIcon className="mr-2 h-7 w-7 text-gray-600" aria-hidden="true" />}
            onSelect={() => {
              onSelection('copy-product')
            }}
          >
            Copy product to all
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <CopyDetailsModal
        copyOption={selectedCopyOption}
        open={modalWillBeShown}
        onClose={() => setSelectedCopyOption(null)}
        onContinue={(option) => {
          setSelectedCopyOption(null)
          if (option) {
            handleCopy(option)
          }
        }}
      />

      <OverlayLoadingSpinner isVisible={saveDataStatus === 'loading'} />
    </div>
  )
}

import clsx from 'clsx'
import { useState } from 'react'
import { motion } from 'motion/react'

import Button from '../../Button'
import { FileRequirements } from './FileRequirements.component'
import { ProductCatalogueProductInterface } from '../../../hooks'
import { Assets, ColourOptions, Devices, Eco, Framing, Materials, Packaging, SizeInformation } from '.'

type CategoryType =
  | 'Assets'
  | 'Colour options'
  | 'Devices'
  | 'Eco credentials'
  | 'File requirements'
  | 'Framing'
  | 'Fulfilment & packaging'
  | 'Materials & printing'
  | 'Packaging'
  | 'Sizes'

export function ProductDetailTabs({ productData }: { productData: ProductCatalogueProductInterface }) {
  const [selectedDetails, setSelectedDetails] = useState<CategoryType>('Assets')
  const tabs = createProductDetailTabs(productData)

  return (
    <>
      <header className="mt-24">
        <h2 className="mt-0">Product details</h2>

        <nav className="relative flex max-w-full gap-6 overflow-hidden overflow-x-auto pt-8">
          {tabs.map((category) => (
            <Button
              className={clsx(
                'relative whitespace-nowrap bg-none px-6 pb-6 font-medium',
                selectedDetails === category && 'text-purple-500'
              )}
              key={category}
              variant="none"
              onClick={() => setSelectedDetails(category)}
            >
              {category}
              <div>
                {selectedDetails === category && (
                  <motion.div
                    className="absolute bottom-0 left-0 right-0 h-[4px] bg-purple-200"
                    layoutId="activeProductDetailsTab"
                  />
                )}
              </div>
            </Button>
          ))}
        </nav>
      </header>

      <TabContent selected={selectedDetails} productData={productData} />
    </>
  )
}

function createProductDetailTabs(productData: ProductCatalogueProductInterface) {
  const tabs: CategoryType[] = ['Assets']

  if (productData.frames?.length > 0) {
    tabs.push('Framing')
  }
  if (productData.colours?.colours.length > 0) {
    tabs.push('Colour options')
  }
  if (productData.imageSpec?.fileTypes?.length > 0) {
    tabs.push('File requirements')
  }
  if (productData.packaging?.length > 0) {
    tabs.push(productData.global ? 'Fulfilment & packaging' : 'Packaging')
  }
  if (productData.eco?.types?.length > 0) {
    tabs.push('Eco credentials')
  }
  if (productData.sizes.data && Object.keys(productData.sizes.data).length > 0) {
    tabs.push('Sizes')
  }
  if (productData.devices && productData.devices.length > 0) {
    tabs.push('Devices')
  }
  if (
    productData.materials?.items?.length > 0 ||
    (productData.printingMethod && productData.printingMethod?.length > 0)
  ) {
    tabs.push('Materials & printing')
  }

  return tabs.sort()
}

function TabContent({
  productData,
  selected
}: {
  productData: ProductCatalogueProductInterface
  selected: CategoryType
}) {
  switch (selected) {
    case 'Assets':
      return <Assets productData={productData} />
    case 'Colour options':
      return <ColourOptions productData={productData} />
    case 'Devices':
      return <Devices productData={productData} />
    case 'Eco credentials':
      return <Eco productData={productData} />
    case 'Framing':
      return <Framing productData={productData} />
    case 'File requirements':
      return <FileRequirements productData={productData} />
    case 'Fulfilment & packaging':
    case 'Packaging':
      return <Packaging productData={productData} />
    case 'Materials & printing':
      return <Materials productData={productData} />
    case 'Sizes':
      return <SizeInformation productData={productData} />
    default:
      console.warn('Tab content does not exist for ' + selected)
      return null
  }
}

import * as React from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import { CheckCircleIcon, ChevronRightIcon } from '@heroicons/react/24/solid'

import { cn } from '../../helpers'
import AnimatedCheckIcon from '../AnimatedCheckIcon'
import { PORTAL_ID } from '../../constants/portalId.const'

const DropdownMenu = DropdownMenuPrimitive.Root

const DropdownMenuPortal = DropdownMenuPrimitive.Portal

const DropdownMenuSub = DropdownMenuPrimitive.Sub

const DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup

const DropdownMenuGroup = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Group>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Group>
>(({ className, children, ...props }, ref) => (
  <DropdownMenuPrimitive.Group ref={ref} className={cn('flex flex-col', className)} {...props}>
    {children}
  </DropdownMenuPrimitive.Group>
))
DropdownMenuGroup.displayName = DropdownMenuPrimitive.Group.displayName

const DropdownMenuTrigger = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <DropdownMenuPrimitive.Trigger
    ref={ref}
    className={cn(
      'relative flex w-full items-center truncate rounded-lg border border-gray-200 bg-white px-3 py-2 text-left outline-offset-1 outline-purple-500 data-[state=open]:cursor-pointer',
      className
    )}
    {...props}
  >
    {children}
  </DropdownMenuPrimitive.Trigger>
))
DropdownMenuTrigger.displayName = DropdownMenuPrimitive.Trigger.displayName

const DropdownMenuSubTrigger = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.SubTrigger>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.SubTrigger> & {
    inset?: boolean
  }
>(({ className, inset, children, ...props }, ref) => (
  <DropdownMenuPrimitive.SubTrigger
    ref={ref}
    className={cn(
      'flex cursor-default select-none items-center rounded-sm px-2 py-1.5 outline-none focus:bg-gray-100 data-[state=open]:bg-gray-100',
      inset && 'pl-8',
      className
    )}
    {...props}
  >
    {children}
    <ChevronRightIcon className="ml-auto h-4 w-4" />
  </DropdownMenuPrimitive.SubTrigger>
))
DropdownMenuSubTrigger.displayName = DropdownMenuPrimitive.SubTrigger.displayName

const DropdownMenuSubContent = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.SubContent>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.SubContent>
>(({ className, ...props }, ref) => (
  <DropdownMenuPrimitive.SubContent
    ref={ref}
    className={cn(
      'z-50 min-w-[8rem] overflow-hidden rounded-md border border-gray-200 bg-white p-1 text-gray-900 shadow-lg data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
      className
    )}
    {...props}
  />
))
DropdownMenuSubContent.displayName = DropdownMenuPrimitive.SubContent.displayName

const DropdownMenuContent = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <DropdownMenuPrimitive.Portal container={document.getElementById(PORTAL_ID)}>
    <DropdownMenuPrimitive.Content
      className={cn(
        'z-50 max-h-96 min-w-[var(--radix-dropdown-menu-trigger-width)] overflow-auto rounded-lg border border-gray-200 bg-white text-gray-800 shadow-lg',
        'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
        className
      )}
      ref={ref}
      sideOffset={sideOffset}
      {...props}
    />
  </DropdownMenuPrimitive.Portal>
))
DropdownMenuContent.displayName = DropdownMenuPrimitive.Content.displayName

const DropdownMenuItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item> & {
    startIcon?: React.ReactNode
  }
>(({ className, children, startIcon, ...props }, ref) => (
  <DropdownMenuPrimitive.Item
    ref={ref}
    className={cn(
      'relative flex cursor-pointer select-none items-center gap-2 border-gray-200 pb-3 pl-6 pr-6 pt-3 outline-none transition-colors duration-200 ease-in hover:bg-gray-100 hover:duration-100 hover:ease-out focus:bg-gray-100 focus:outline-none focus-visible:bg-[hsl(0deg,0%,90%)] focus-visible:duration-100 focus-visible:ease-out data-[disabled]:pointer-events-none data-[disabled]:opacity-50',

      className
    )}
    {...props}
  >
    {startIcon}
    {children}
  </DropdownMenuPrimitive.Item>
))
DropdownMenuItem.displayName = DropdownMenuPrimitive.Item.displayName

const DropdownMenuCheckboxItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.CheckboxItem>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.CheckboxItem>
>(({ className, children, checked, ...props }, ref) => (
  <DropdownMenuPrimitive.CheckboxItem
    ref={ref}
    className={cn(
      'relative flex cursor-pointer select-none items-center gap-2 border-gray-200 pb-3 pl-10 pr-6 pt-3 outline-none transition-colors duration-200 ease-in hover:bg-gray-100 hover:duration-100 hover:ease-out focus:bg-gray-100 focus:outline-none focus-visible:bg-[hsl(0deg,0%,90%)] focus-visible:duration-100 focus-visible:ease-out data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
      className
    )}
    checked={checked}
    {...props}
  >
    <span className="absolute left-2 flex items-center justify-center">
      <AnimatedCheckIcon className="h-6 w-6" isVisible={Boolean(checked)} />
    </span>
    {children}
  </DropdownMenuPrimitive.CheckboxItem>
))
DropdownMenuCheckboxItem.displayName = DropdownMenuPrimitive.CheckboxItem.displayName

const DropdownMenuRadioItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.RadioItem>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.RadioItem>
>(({ className, children, ...props }, ref) => (
  <DropdownMenuPrimitive.RadioItem
    ref={ref}
    className={cn(
      'relative flex cursor-pointer select-none items-center gap-2 border-gray-200 pb-3 pl-10 pr-6 pt-3 outline-none transition-colors duration-200 ease-in hover:bg-gray-100 hover:duration-100 hover:ease-out focus:bg-gray-100 focus:outline-none focus-visible:bg-[hsl(0deg,0%,90%)] focus-visible:duration-100 focus-visible:ease-out data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
      className
    )}
    {...props}
  >
    <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
      <DropdownMenuPrimitive.ItemIndicator>
        <CheckCircleIcon className="h-2 w-2 fill-current" />
      </DropdownMenuPrimitive.ItemIndicator>
    </span>
    {children}
  </DropdownMenuPrimitive.RadioItem>
))
DropdownMenuRadioItem.displayName = DropdownMenuPrimitive.RadioItem.displayName

const DropdownMenuLabel = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Label> & {
    inset?: boolean
  }
>(({ className, inset, ...props }, ref) => (
  <DropdownMenuPrimitive.Label
    ref={ref}
    className={cn('font-semibold px-2 py-1.5 text-sm', inset && 'pl-8', className)}
    {...props}
  />
))
DropdownMenuLabel.displayName = DropdownMenuPrimitive.Label.displayName

const DropdownMenuSeparator = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <DropdownMenuPrimitive.Separator ref={ref} className={cn('mx-6 h-px bg-gray-200', className)} {...props} />
))
DropdownMenuSeparator.displayName = DropdownMenuPrimitive.Separator.displayName

const DropdownMenuShortcut = ({ className, ...props }: React.HTMLAttributes<HTMLSpanElement>) => {
  return <span className={cn('ml-auto text-xs tracking-widest opacity-60', className)} {...props} />
}
DropdownMenuShortcut.displayName = 'DropdownMenuShortcut'

const DropdownMenuSelectedCounter = React.forwardRef<
  React.ElementRef<React.ForwardRefExoticComponent<React.RefAttributes<HTMLSpanElement>>>,
  React.ComponentPropsWithoutRef<React.ForwardRefExoticComponent<React.RefAttributes<HTMLSpanElement>>> & {
    className?: string
    selectedCount: number
  }
>(({ className, selectedCount, ...props }, ref) => (
  <AnimatePresence>
    {selectedCount > 0 && (
      <motion.span
        ref={ref}
        animate={{ scale: 1 }}
        className={cn(
          'ml-auto flex aspect-square h-[18px] w-[18px] items-center justify-center rounded-full bg-gray-700 text-[10px] text-white',
          selectedCount > 99 && 'w-[20px] text-[9px]',
          className
        )}
        exit={{ scale: 0 }}
        initial={{ scale: 0.5 }}
        {...props}
      >
        <motion.span animate={{ opacity: 1 }} initial={{ opacity: 0 }} key={selectedCount}>
          {selectedCount > 99 ? '99+' : selectedCount}
        </motion.span>
      </motion.span>
    )}
  </AnimatePresence>
))
DropdownMenuSelectedCounter.displayName = 'DropdownMenuSelectedCounter'

export {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuCheckboxItem,
  DropdownMenuRadioItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuGroup,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuRadioGroup,
  DropdownMenuSelectedCounter
}

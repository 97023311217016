import clsx from 'clsx'
import toast from 'react-hot-toast'
import { useEffect, useState } from 'react'
import { PhotoIcon } from '@heroicons/react/20/solid'
import { AnimatePresence, motion } from 'motion/react'

import { createToast } from '../../Toast'
import SupportLink from '../../SupportLink'
import OverlayPortal from '../../OverlayPortal'
import ClipboardCopy from '../../ClipboardCopy'
import LoadingSpinner from '../../LoadingSpinner'
import { IMAGE_LIBRARY_TOASTS } from '../constants'
import { ThumbnailPolling } from './ThumbnailPolling.component'
import { ImageLibraryModeType } from '../ImageLibrary.component'
import ImageLibraryFileTypePill from '../../ImageLibraryFileTypePill'
import { FetchErrorInterface, StatusType } from '../../../interfaces'
import { ImageLibraryImageInterface, useImageLibraryImages } from '../../../hooks'
import { DeleteFromImageLibraryButton } from './DeleteFromImageLibraryButton.component'
import { getDefaultImageLibraryErrorMessage, requestImageLibraryThumbnailGeneration } from '../../../helpers'

interface ImageTilePropsInterface {
  className?: string
  imageLibraryImage: ImageLibraryImageInterface
  imageLibraryMode: ImageLibraryModeType
  index: number
  onSelectImage?: (imageLibraryImage: ImageLibraryImageInterface) => void
}

export function ImageTile({
  className,
  imageLibraryImage,
  imageLibraryMode,
  index,
  onSelectImage
}: ImageTilePropsInterface) {
  const { mutateImageLibraryImages } = useImageLibraryImages()

  const [imageFetchStatus, setImageFetchStatus] = useState<StatusType>('idle')
  const [pollForThumbnailImageId, setPollForThumbnailImageId] = useState<string | null>(null)

  useEffect(() => {
    return () => {
      toast.dismiss(IMAGE_LIBRARY_TOASTS.ERROR)
    }
  }, [])

  function handleImageSelectSuccess(imageData: ImageLibraryImageInterface) {
    mutateImageLibraryImages()
    if (onSelectImage) {
      window.analytics.track('Image Library - image selected')
      onSelectImage(imageData)
      return
    }
    setImageFetchStatus('success')
  }

  function handleImageSelectError({
    errorCode,
    errorMessage = null
  }: {
    errorCode: string | number
    errorMessage?: string | null
  }) {
    createToast({
      content: (
        <>
          {errorMessage && <p className="mb-2">{errorMessage}</p>}
          <p>
            Please try again later or <SupportLink>contact support</SupportLink> if the issue persists
          </p>
        </>
      ),
      duration: Infinity,
      footer: (
        <span className="text-sm text-gray-600">
          Code: {errorCode} <ClipboardCopy showText={false} text={errorCode.toString()} />
        </span>
      ),
      heading: 'Failed to select image',
      id: IMAGE_LIBRARY_TOASTS.ERROR,
      type: 'error-with-close'
    })
    setImageFetchStatus('error')
  }

  async function handleSelectImage() {
    toast.dismiss(IMAGE_LIBRARY_TOASTS.ERROR)
    setImageFetchStatus('loading')

    try {
      const imageData = await requestImageLibraryThumbnailGeneration(imageLibraryImage.public_id)

      if (imageData.upload_status !== 'success') {
        setPollForThumbnailImageId(imageData.public_id)
        return
      }

      handleImageSelectSuccess(imageData)
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<{ detail?: string }>
      const errorMessage = getDefaultImageLibraryErrorMessage(errorResponse)
      const errorCode = `${imageLibraryImage.public_id}-${errorResponse.status ?? '0'}`
      handleImageSelectError({ errorCode, errorMessage })
    }
  }

  const imageDimensionsLabel =
    imageLibraryImage.pixel_width && imageLibraryImage.pixel_height ? (
      <>
        {imageLibraryImage.pixel_width}
        {'x'}
        {imageLibraryImage.pixel_height}
        {'px'}
      </>
    ) : null

  const pageCountLabel =
    imageLibraryImage.pdf_page_count && imageLibraryImage.pdf_page_count > 1 ? (
      <>
        {imageLibraryImage.pdf_page_count}
        {' pages'}
      </>
    ) : null

  return (
    <div className={clsx('relative mx-auto min-h-[200px] w-full md:min-h-[260px]', className)}>
      <button
        data-test={`image-library-tile-${index}`}
        disabled={imageLibraryMode === 'manage' || imageFetchStatus === 'loading'}
        className="group w-full text-left text-gray-700 antialiased disabled:pointer-events-none"
        onClick={handleSelectImage}
      >
        <figure>
          <ImageThumbnail imageLibraryImage={imageLibraryImage} />
          <figcaption className="mt-1 line-clamp-2 break-words font-medium text-gray-900">
            {imageLibraryImage.name}
          </figcaption>
        </figure>

        <div className="flex items-center justify-between text-sm">
          <span className="flex-1">{pageCountLabel || imageDimensionsLabel}</span>
          <ImageLibraryFileTypePill fileType={imageLibraryImage.file_type} />
        </div>
      </button>

      <AnimatePresence>
        {imageLibraryMode === 'manage' && (
          <motion.div
            className="absolute inset-0 grid aspect-square w-full place-content-center bg-white/25"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <DeleteFromImageLibraryButton imageLibraryImage={imageLibraryImage} />
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {imageFetchStatus === 'loading' && (
          <motion.div
            className="absolute inset-0 grid aspect-square w-full place-content-center bg-white/25"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <LoadingSpinner className="h-10 w-10" />
          </motion.div>
        )}
      </AnimatePresence>

      {pollForThumbnailImageId && (
        <ThumbnailPolling
          imageLibraryId={pollForThumbnailImageId}
          onComplete={async ({ imageLibraryImage, imageLibraryImageFetchError }) => {
            setPollForThumbnailImageId(null)

            if (imageLibraryImageFetchError) {
              const errorMessage = getDefaultImageLibraryErrorMessage(imageLibraryImageFetchError)
              const errorCode = `P-${imageLibraryImageFetchError?.status ?? '0'}-${pollForThumbnailImageId}`
              handleImageSelectError({ errorCode, errorMessage })
              return
            }

            if (!imageLibraryImage || imageLibraryImage.upload_status !== 'success') {
              const errorCode = `P-${imageLibraryImage?.upload_status ?? 'P'}-${pollForThumbnailImageId}`
              handleImageSelectError({ errorCode })
              return
            }

            handleImageSelectSuccess(imageLibraryImage)
          }}
        />
      )}

      {imageFetchStatus === 'loading' && <OverlayPortal />}
    </div>
  )
}

function ImageThumbnail({ imageLibraryImage }: { imageLibraryImage: ImageLibraryImageInterface }) {
  if (!imageLibraryImage.thumbnail_urls.small) {
    return (
      <div className="grid aspect-square w-full place-content-center bg-gray-100">
        <PhotoIcon className="h-10 w-10 text-gray-400" />
      </div>
    )
  }

  return (
    <div className="relative flex aspect-square items-center justify-center">
      <img
        alt={imageLibraryImage.name}
        className="block aspect-square h-auto w-full max-w-full object-cover object-center opacity-100 transition-opacity group-hover:opacity-0"
        loading="lazy"
        src={imageLibraryImage.thumbnail_urls.small}
      />
      <img
        alt={imageLibraryImage.name}
        className={`absolute inset-0 m-auto block opacity-0 transition-opacity group-hover:opacity-100 ${
          (imageLibraryImage.pixel_width ?? 0) > (imageLibraryImage.pixel_height ?? 0)
            ? 'h-auto w-full'
            : 'h-full w-auto'
        } `}
        loading="lazy"
        src={imageLibraryImage.thumbnail_urls.small}
      />
    </div>
  )
}

import { useEffect } from 'react'
import { useParams } from 'react-router'

import BrowseProduct from '../BrowseProduct'
import SavedItemsModalButton from '../SavedItemsModalButton'
import { DEFAULT_DOCUMENT_TITLE, ROUTE_PATHS } from '../../constants'
import { Catalogue, ProductCatalogueSearch } from './components'
import Breadcrumbs, { BreadcrumbInterface } from '../Breadcrumbs/Breadcrumbs.component'
import { CatalogueCategoryInterface, useProductCatalogue } from '../../hooks'

export default function BrowseProducts() {
  const { categorySlug, subCategorySlug, subSubCategorySlug, productSlug } = useParams<{
    categorySlug?: string
    subCategorySlug?: string
    subSubCategorySlug?: string
    productSlug?: string
  }>()
  const { productCatalogue } = useProductCatalogue()

  const categoryData = categorySlug && productCatalogue ? productCatalogue[categorySlug] : undefined
  const subCategoryData = categoryData && subCategorySlug ? categoryData.subCategories[subCategorySlug] : undefined
  const subSubCategoryData =
    subCategoryData && subSubCategorySlug ? subCategoryData.subCategories[subSubCategorySlug] : undefined

  const categoryProductData =
    categoryData && subCategorySlug && Object.keys(categoryData.products).length > 0
      ? categoryData.products[subCategorySlug]
      : undefined
  const subCategoryProductData =
    subCategoryData && subSubCategorySlug && Object.keys(subCategoryData.products).length > 0
      ? subCategoryData.products[subSubCategorySlug]
      : undefined
  const subSubCategoryProductData =
    subSubCategoryData && productSlug && Object.keys(subSubCategoryData.products).length > 0
      ? subSubCategoryData.products[productSlug]
      : undefined

  useEffect(() => {
    document.title = `${
      subSubCategoryProductData?.name ??
      subCategoryProductData?.name ??
      categoryProductData?.name ??
      subSubCategoryData?.name ??
      subCategoryData?.name ??
      categoryData?.name ??
      'Browse Products'
    } | Prodigi`

    return () => {
      document.title = DEFAULT_DOCUMENT_TITLE
    }
  }, [
    categoryData?.name,
    subCategoryData?.name,
    subSubCategoryData?.name,
    categoryProductData?.name,
    subCategoryProductData?.name,
    subSubCategoryProductData?.name
  ])

  if (categoryProductData?.slug || subCategoryProductData?.slug || subSubCategoryProductData?.slug) {
    return (
      <div className="container mx-auto py-6 lg:py-8 xl:max-w-full">
        <BrowseProduct
          slug={subSubCategoryProductData?.slug ?? subCategoryProductData?.slug ?? categoryProductData?.slug ?? ''}
        />
      </div>
    )
  }

  return (
    <div className="container mx-auto py-6 lg:py-8 xl:max-w-full">
      <BrowseProductsBreadCrumbs
        categoryData={categoryData}
        subCategoryData={subCategoryData}
        subSubCategoryData={subSubCategoryData}
      />

      <h1>{subSubCategoryData?.name ?? subCategoryData?.name ?? categoryData?.name ?? 'Products'}</h1>

      <div className="mt-8 flex flex-wrap gap-2">
        <div className="mr-auto flex w-full items-center justify-self-start md:w-[300px]">
          <ProductCatalogueSearch />
        </div>
        <div className="ml-auto">
          <SavedItemsModalButton />
        </div>
      </div>

      <Catalogue />
    </div>
  )
}

function BrowseProductsBreadCrumbs({
  categoryData,
  subCategoryData,
  subSubCategoryData
}: {
  categoryData?: CatalogueCategoryInterface
  subCategoryData?: CatalogueCategoryInterface
  subSubCategoryData?: CatalogueCategoryInterface
}) {
  const formattedBreadcrumbs: BreadcrumbInterface[] = [
    {
      href: ROUTE_PATHS.PRODUCT_CATALOGUE,
      title: 'Products'
    }
  ]

  if (categoryData) {
    formattedBreadcrumbs.push({
      href: `${ROUTE_PATHS.PRODUCT_CATALOGUE}/${categoryData.fullSlug}`,
      title: categoryData.name
    })
  }

  if (subCategoryData) {
    formattedBreadcrumbs.push({
      href: `${ROUTE_PATHS.PRODUCT_CATALOGUE}/${subCategoryData.fullSlug}`,
      title: subCategoryData.name
    })
  }

  if (subSubCategoryData) {
    formattedBreadcrumbs.push({
      href: `${ROUTE_PATHS.PRODUCT_CATALOGUE}/${subSubCategoryData.fullSlug}`,
      title: subSubCategoryData.name
    })
  }

  if (formattedBreadcrumbs.length === 1) {
    return null
  }

  return (
    <div className="mb-10">
      <Breadcrumbs pages={formattedBreadcrumbs} />
    </div>
  )
}

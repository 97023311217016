import Countries from 'country-list'

import { Dispatch, FormEvent, MouseEvent, ReactNode } from 'react'
import { Redirect } from 'react-router'
import { Link } from 'react-router-dom'

import Button from '../../Button'
import CheckboxField from '../../Checkbox'
import SelectField from '../../SelectField'

import { RegisterReducerActionTypeEnum } from '../enums'
import { RegisterFormBoolFieldInterface, RegisterFormStringFieldInterface } from '../interfaces'
import { RegisterReducerActionType } from '../types'

import { motion } from 'motion/react'

import OverlayPortal from '../../OverlayPortal'

const CURRENCIES = {
  GBP: 'GB Pounds',
  USD: 'US Dollars',
  EUR: 'Euro'
}

interface PropTypesInterface {
  accountCreationLoading: boolean
  accountCreationError: string | null
  agreedTerms: RegisterFormBoolFieldInterface
  autoFocusFormField: boolean
  billingCurrency: RegisterFormStringFieldInterface
  children?: ReactNode
  countryName: RegisterFormStringFieldInterface
  onStepChange: (event: MouseEvent<HTMLAnchorElement | HTMLButtonElement>, step: number) => void
  onSubmit: (event: FormEvent<HTMLFormElement>) => void
  registerFormDispatch: Dispatch<RegisterReducerActionType>
}

const COUNTRY_NAMES = Countries().getNames()

export default function Step4({
  accountCreationError,
  accountCreationLoading,
  agreedTerms,
  autoFocusFormField,
  billingCurrency,
  children,
  countryName,
  onSubmit,
  registerFormDispatch
}: PropTypesInterface) {
  if (accountCreationError === 'is already taken') {
    return <Redirect to={`/register/1` + window.location.search} />
  }

  return (
    <motion.form
      onSubmit={onSubmit}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="flex min-h-full w-full flex-col"
    >
      <div className="flex flex-col">
        <span className="mt-2 flex items-center justify-between">
          <label className="text-sm uppercase text-gray-600" htmlFor="country">
            Country
          </label>
        </span>
        <SelectField
          dataTest="register-country"
          autoFocus={autoFocusFormField}
          id="country"
          name="country"
          value={countryName.value}
          onChange={(e) => {
            registerFormDispatch({
              type: RegisterReducerActionTypeEnum.SetCountryName,
              payload: {
                countryName: e.target.value,
                validity: e.target.validity
              }
            })
          }}
          required
        >
          <option value="" disabled>
            Select country
          </option>
          <optgroup>
            <option value="United States">United States</option>
            <option value="United Kingdom">United Kingdom</option>
            <option value="Australia">Australia</option>
          </optgroup>
          <optgroup>
            {COUNTRY_NAMES.map((name) => (
              <option value={name} key={name}>
                {name}
              </option>
            ))}
          </optgroup>
        </SelectField>

        <span className="mt-1 text-right text-sm text-red-500">
          <motion.span animate={countryName.error ? { opacity: 1 } : { opacity: 0 }} initial={{ opacity: 0 }}>
            {countryName.error}&nbsp;
          </motion.span>
        </span>

        <span className="flex items-center justify-between">
          <label className="text-sm uppercase text-gray-600" htmlFor="currency">
            Preferred billing currency
          </label>
        </span>
        <SelectField
          className="mt-2"
          dataTest="register-currency"
          id="currency"
          name="currency"
          value={billingCurrency.value}
          onChange={(e) => {
            registerFormDispatch({
              type: RegisterReducerActionTypeEnum.SetBillingCurrency,
              payload: {
                billingCurrency: e.target.value,
                validity: e.target.validity
              }
            })
          }}
          required
        >
          <option value="" disabled>
            Select currency
          </option>
          {Object.keys(CURRENCIES).map((key) => (
            <option value={key} key={key}>
              {CURRENCIES[key as keyof typeof CURRENCIES]}
            </option>
          ))}
        </SelectField>

        <span className="mt-1 text-right text-sm text-red-500">
          <motion.span animate={billingCurrency.error ? { opacity: 1 } : { opacity: 0 }} initial={{ opacity: 0 }}>
            {billingCurrency.error}&nbsp;
          </motion.span>
        </span>

        <span className="flex w-full items-center">
          <CheckboxField
            disabled={false}
            checked={agreedTerms.value}
            onChange={(e) => {
              registerFormDispatch({
                type: RegisterReducerActionTypeEnum.SetAgreedTerms,
                payload: {
                  agreedTerms: e.target.checked
                }
              })
            }}
            id="agreeTerms"
          />
          <label className="cursor-pointer pl-3" htmlFor="agreeTerms">
            I agree to the Prodigi{' '}
            <a
              className="text-purple-500"
              href="https://www.prodigi.com/terms-of-use/"
              target="_blank"
              rel="noreferrer noopener"
            >
              Terms of Use
            </a>
            .
          </label>
        </span>

        <Button
          type="submit"
          isLoading={accountCreationLoading}
          disabled={Boolean(
            countryName.error ||
              billingCurrency.error ||
              !countryName.dirty ||
              !billingCurrency.dirty ||
              !agreedTerms.value ||
              accountCreationLoading
          )}
          className="mt-16"
        >
          Create account
        </Button>

        {children}

        {accountCreationError && (
          <div className="pt-2 text-red-500">
            {accountCreationError === 'is already taken' ? (
              <>
                This email address is already registered. Please <Link to="/login">log in</Link> or{' '}
                <Link to="/password-reset">reset your password</Link>.
              </>
            ) : (
              accountCreationError
            )}
          </div>
        )}
      </div>

      {/* Blocks any clicks while the form is loading */}
      {accountCreationLoading && <OverlayPortal />}
    </motion.form>
  )
}

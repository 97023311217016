import toast from 'react-hot-toast'
import { ReactNode, useEffect, useState } from 'react'
import { ChevronUpDownIcon } from '@heroicons/react/24/outline'

import {
  MerchantPreferencesErrorInterface,
  OrderEditWindowType,
  useMerchantPreferences,
  useMerchantService
} from '../../../../../hooks'
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger
} from '../../../../DropdownMenu'
import {
  SETTINGS_UPDATE_ERROR_TOAST_ID,
  SETTINGS_UPDATE_SUCCESS_TOAST_ID
} from '../../../../../components/Settings/constants'
import Button from '../../../../../components/Button'
import Skeleton from '../../../../..//components/Skeleton'
import RadioGroup from '../../../../../components/RadioGroup'
import { SettingsPanel } from '../../SettingsPanel.component'
import { updateMerchantPreferences } from '../../../../../helpers'
import OverlayPortal from '../../../../../components/OverlayPortal'
import { FetchErrorInterface, StatusType } from '../../../../../interfaces'
import { createErrorToast, createToast } from '../../../../../components/Toast'

const ORDER_EDIT_WINDOW: Array<{ value: OrderEditWindowType; label: string }> = [
  { value: 'Immediate', label: 'None, process immediately' },
  { value: 'TwoHours', label: '2 hours' },
  { value: 'SixHours', label: '6 hours' },
  { value: 'TwentyFourHours', label: '24 hours' },
  { value: 'Indefinite', label: 'Pause indefinitely, until manually released' }
]

interface SelectedCustomWindowInterface {
  hours: number
  minutes: number
}

export function OrderEditWindowPreferences() {
  const { merchantDetails } = useMerchantService()
  const { merchantPreferences, mutateMerchantPreferences } = useMerchantPreferences()

  const [selectedWindow, setSelectedWindow] = useState<OrderEditWindowType | null>(null)
  const [selectedCustomWindow, setSelectedCustomWindow] = useState<SelectedCustomWindowInterface>({
    hours: 0,
    minutes: 5
  })
  const [orderWindowUpdateProgress, setOrderWindowUpdateProgress] = useState<StatusType>('idle')

  useEffect(() => {
    return () => {
      toast.dismiss(SETTINGS_UPDATE_SUCCESS_TOAST_ID)
      toast.dismiss(SETTINGS_UPDATE_ERROR_TOAST_ID)
    }
  }, [])

  useEffect(() => {
    if (merchantPreferences && selectedWindow === null) {
      setSelectedWindow(merchantPreferences.orderEditWindow)
      if (merchantPreferences.orderEditCustomWindow) {
        const hours = Math.floor(merchantPreferences.orderEditCustomWindow / 60)
        let minutes = merchantPreferences.orderEditCustomWindow % 60

        if (minutes === 0 && hours === 0) {
          minutes = 5
        }
        setSelectedCustomWindow({ hours, minutes })
      }
    }
  }, [merchantPreferences, selectedWindow])

  async function handleOrderWindowUpdate() {
    toast.dismiss(SETTINGS_UPDATE_SUCCESS_TOAST_ID)
    toast.dismiss(SETTINGS_UPDATE_ERROR_TOAST_ID)

    try {
      if (!merchantPreferences) {
        throw Error('No merchant preferences')
      }
      setOrderWindowUpdateProgress('loading')

      const selected = selectedWindow ?? 'Immediate'

      const customWindow =
        selected === 'Custom' && selectedCustomWindow
          ? selectedCustomWindow.hours * 60 + selectedCustomWindow.minutes
          : null

      await updateMerchantPreferences(
        {
          ...merchantPreferences,
          orderEditWindow: selected,
          orderEditCustomWindow: customWindow
        },
        merchantDetails?.id
      )
      await mutateMerchantPreferences()
      setOrderWindowUpdateProgress('success')
      createToast({ heading: 'Updated successfully', id: SETTINGS_UPDATE_SUCCESS_TOAST_ID, type: 'success' })
      window.analytics.track('Order edit duration set', { option: selected, customWindowInMins: customWindow })
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<MerchantPreferencesErrorInterface>

      createErrorToast({
        errorCode: errorResponse.responseBodyJson?.traceParent ?? errorResponse?.status ?? '0',
        heading: 'An error occurred while updating order edit window',
        id: SETTINGS_UPDATE_ERROR_TOAST_ID
      })
      setOrderWindowUpdateProgress('error')
    }
  }

  if (!merchantPreferences || !selectedWindow) {
    return (
      <PanelContainer>
        <div className="flex w-full flex-col">
          <span className="mb-4 text-gray-600">Window duration:</span>

          <div className="flex flex-col -space-y-px">
            {ORDER_EDIT_WINDOW.map((_, index) => (
              <div key={index} className="space-x-5 py-2">
                <Skeleton className="h-[21px] w-full p-0 sm:w-1/2" />
              </div>
            ))}
          </div>
        </div>

        <Button className="mt-8" disabled={true}>
          Save
        </Button>
      </PanelContainer>
    )
  }

  const canSave =
    selectedWindow !== 'Custom'
      ? true
      : selectedCustomWindow.hours === 0
      ? Boolean(selectedCustomWindow.minutes > 0)
      : true

  return (
    <PanelContainer>
      <div className="flex min-h-full w-fit flex-col">
        <span className="mb-4 text-gray-600">Window duration:</span>

        <RadioGroup
          ariaLabel="Window duration"
          items={ORDER_EDIT_WINDOW}
          renderOtherOption={() => (
            <CustomOrderWindow
              selectedCustomWindow={selectedCustomWindow}
              setSelectedCustomWindow={setSelectedCustomWindow}
            />
          )}
          selectedValue={selectedWindow === 'Custom' ? 'Other' : selectedWindow}
          showOtherOptionInput={true}
          onChange={(selection) => {
            const selectedOption = selection === 'Other' ? 'Custom' : (selection as OrderEditWindowType)
            setSelectedWindow(selectedOption)
          }}
        />

        <Button
          className="mt-8 w-fit"
          disabled={!canSave}
          isLoading={orderWindowUpdateProgress === 'loading'}
          type="submit"
          onClick={(event) => {
            event.preventDefault()
            handleOrderWindowUpdate()
          }}
        >
          Save
        </Button>
      </div>

      {orderWindowUpdateProgress === 'loading' && <OverlayPortal className="cursor-wait" />}
    </PanelContainer>
  )
}

function PanelContainer({ children }: { children?: ReactNode }) {
  return (
    <SettingsPanel
      className="border"
      subtitle={
        <>
          <span className="mt-4 max-w-lg text-gray-600">
            Add a pause to orders before they are sent to production, during which time they can be edited or cancelled.
            Once in production, orders cannot be changed or refunded.
          </span>
          <span className="mt-4 max-w-lg text-gray-600">
            <strong>Note:</strong> Pausing orders will change order creation outcomes in the{' '}
            <a
              className="text-purple-500 hover:underline"
              href="https://www.prodigi.com/print-api/docs/reference/#create-order"
              title="API documentation"
              rel="noreferrer"
              target="_blank"
            >
              API
            </a>
            .
          </span>
        </>
      }
      title={<h2>Order edit window</h2>}
    >
      {children}
    </SettingsPanel>
  )
}

function CustomOrderWindow({
  selectedCustomWindow,
  setSelectedCustomWindow
}: {
  selectedCustomWindow: SelectedCustomWindowInterface
  setSelectedCustomWindow: (newState: React.SetStateAction<SelectedCustomWindowInterface>) => void
}) {
  const hourList = []
  for (let i = 0; i < 48; i++) {
    hourList.push(i)
  }

  const minuteList = []
  for (let i = 0; i < 60; i = i + 5) {
    minuteList.push(i)
  }

  return (
    <div className="flex space-x-5">
      <div className="h-6 w-6" />
      <div className="ml-6 flex flex-col gap-y-4 md:flex-row md:gap-x-4">
        <CustomWindowDropdown
          items={hourList}
          selected={selectedCustomWindow.hours}
          title="hours"
          setSelectedCustomWindow={(selected) =>
            setSelectedCustomWindow((state) => ({
              hours: +selected,
              minutes: state.minutes
            }))
          }
        />
        <CustomWindowDropdown
          items={minuteList}
          selected={selectedCustomWindow.minutes}
          title="mins"
          setSelectedCustomWindow={(selected) =>
            setSelectedCustomWindow((state) => ({
              hours: state.hours,
              minutes: +selected
            }))
          }
        />
      </div>
    </div>
  )
}

function CustomWindowDropdown({
  items,
  selected,
  title,
  setSelectedCustomWindow
}: {
  items: number[]
  selected: number
  title: string
  setSelectedCustomWindow: (selected: number) => void
}) {
  return (
    <div className="flex items-center gap-x-4">
      <DropdownMenu modal={false}>
        <DropdownMenuTrigger className="h-16 w-24">
          <ChevronUpDownIcon aria-hidden="true" className="mr-2 h-7 w-7 text-gray-400" />
          <span>{selected}</span>
        </DropdownMenuTrigger>

        <DropdownMenuContent className="min-w-32" align="start">
          {items.map((value) => (
            <DropdownMenuCheckboxItem
              checked={selected === value}
              key={value.toString()}
              onSelect={() => {
                setSelectedCustomWindow(+value)
              }}
            >
              {value}
            </DropdownMenuCheckboxItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
      <span>{title}</span>
    </div>
  )
}

import { useMemo } from 'react'
import { Column } from '@tanstack/react-table'
import { Bars3Icon } from '@heroicons/react/20/solid'
import { useHistory, useLocation } from 'react-router-dom'

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSelectedCounter,
  DropdownMenuTrigger
} from '../../DropdownMenu'
import { VariantRowType } from '../../../hooks'
import { VARIANTS_QUERY_PARAM_PREFIX } from './Variants.component'

export const VARIANTS_FILTER_VALUE_SEPARATOR = '||'

export interface VariantsColumnFilterType {
  content: React.ReactNode
  searchLabel: string
  value: string
}

export function VariantsColumnFilter({ column }: { column: Column<VariantRowType, unknown> }) {
  const history = useHistory()
  const { pathname, search } = useLocation()

  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  const filterType = column.columnDef.meta?.filterType
  const columnFilterValue = column.getFilterValue()

  const selectedValues = (columnFilterValue as string)?.split(VARIANTS_FILTER_VALUE_SEPARATOR).filter(Boolean) ?? []

  const uniqueColumnOptions = column.columnDef.meta?.options

  function handleUpdateSelectedValues(selectedValues: string[]) {
    const newSearchParams = new URLSearchParams(searchParams)

    if (selectedValues.length === 0) {
      newSearchParams.delete(`${VARIANTS_QUERY_PARAM_PREFIX}${column.id}`)
      history.replace({ pathname, search: newSearchParams.toString() })
      return
    }

    const newColumnFilterValue = selectedValues.join(VARIANTS_FILTER_VALUE_SEPARATOR)
    newSearchParams.set(`${VARIANTS_QUERY_PARAM_PREFIX}${column.id}`, newColumnFilterValue)
    history.replace({ pathname, search: newSearchParams.toString() })
  }

  function handleSelection(value: string) {
    let updatedSelectedValues: string[] = []
    if (selectedValues.includes(value)) {
      updatedSelectedValues = selectedValues.filter((selectedValue) => selectedValue !== value)
    } else {
      updatedSelectedValues = [...selectedValues, value]
    }
    handleUpdateSelectedValues(updatedSelectedValues)
  }

  function handleClear() {
    const newSearchParams = new URLSearchParams(searchParams)
    newSearchParams.delete(`${VARIANTS_QUERY_PARAM_PREFIX}${column.id}`)
    history.replace({ pathname, search: newSearchParams.toString() })
  }

  if (filterType === 'multi' && uniqueColumnOptions) {
    return (
      <DropdownMenu modal={false}>
        <DropdownMenuTrigger className="relative flex pr-4">
          <Bars3Icon className="mr-2 h-7 w-7 text-gray-500" aria-hidden="true" />
          <span className="mr-10">{column.id}</span>

          <DropdownMenuSelectedCounter className="absolute right-2" selectedCount={selectedValues.length} />
        </DropdownMenuTrigger>

        <DropdownMenuContent align="end">
          {uniqueColumnOptions.map((option: string | VariantsColumnFilterType) => {
            if (typeof option !== 'string') {
              return (
                <DropdownMenuCheckboxItem
                  checked={selectedValues.includes(option.value)}
                  key={option.value.toString()}
                  onSelect={(e) => {
                    e.preventDefault()
                    handleSelection(option.value)
                  }}
                >
                  {option.content}
                </DropdownMenuCheckboxItem>
              )
            }

            return (
              <DropdownMenuCheckboxItem
                checked={selectedValues.includes(option)}
                key={option.toString()}
                onSelect={(e) => {
                  e.preventDefault()
                  handleSelection(option)
                }}
              >
                {option}
              </DropdownMenuCheckboxItem>
            )
          })}
          <DropdownMenuItem
            className="sticky bottom-0 bg-white pl-10 text-purple-600"
            onSelect={() => {
              handleClear()
            }}
          >
            Clear
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    )
  }

  // Other filter types commented out for now, will need styling updates if needed in future

  // if (filterType === 'text') {
  //   return (
  //     <div className="w-80">
  //       <DebouncedFilterInput column={column} columnFilterValue={(columnFilterValue ?? '') as string} />
  //     </div>
  //   )
  // }

  // if (filterType === 'select' && uniqueColumnOptions) {
  //   return (
  //     <div className="mt-2">
  //       <SelectField
  //         value={(columnFilterValue as string)?.toString() ?? ''}
  //         onChange={(event) => {
  //           const newColumnFilterValue = event.target.value
  //           const newSearchParams = new URLSearchParams(searchParams)
  //           newSearchParams.set(`${VARIANTS_QUERY_PARAM_PREFIX}${column.id}`, newColumnFilterValue)
  //           history.replace({ pathname, search: newSearchParams.toString() })
  //         }}
  //       >
  //         <option value="">All {column.id.toLowerCase()}s</option>
  //         {uniqueColumnOptions.map((value) => (
  //           <option value={value} key={value}>
  //             {value}
  //           </option>
  //         ))}
  //       </SelectField>
  //     </div>
  //   )
  // }

  return null
}

// function DebouncedFilterInput({
//   column,
//   columnFilterValue
// }: {
//   column: Column<VariantRowType, unknown>
//   columnFilterValue: string
// }) {
//   const [searchValue, setSearchValue] = useState(columnFilterValue)

//   const history = useHistory()
//   const { pathname, search } = useLocation()

//   const searchParams = useMemo(() => new URLSearchParams(search), [search])

//   // Sync with table state column filter value
//   useEffect(() => {
//     setSearchValue(columnFilterValue)
//   }, [columnFilterValue])

//   const debouncedSearch = useDebouncedCallback((newColumnFilterValue) => {
//     const newSearchParams = new URLSearchParams(searchParams)
//     if (!newColumnFilterValue) {
//       newSearchParams.delete(`${VARIANTS_QUERY_PARAM_PREFIX}${column.id}`)
//     } else {
//       newSearchParams.set(`${VARIANTS_QUERY_PARAM_PREFIX}${column.id}`, newColumnFilterValue)
//     }
//     history.replace({ pathname, search: newSearchParams.toString() })
//   }, 500)

//   return (
//     <TextField
//       placeholder={`Search (${column.getFacetedUniqueValues().size})`}
//       type="text"
//       value={searchValue}
//       onChange={(event) => {
//         setSearchValue(event.target.value)
//         debouncedSearch(event.target.value)
//       }}
//     />
//   )
// }

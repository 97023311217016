// @flow
import type { Charge, OrderCost, ChargeType } from '../../types'
import { CURRENCY_CODE } from '../../data/currencies'
import { formatCost } from './formatCost'
import { combineCosts } from './combineCosts'
import { CHARGE_TYPE } from '../../data'

const { ITEM, SHIPPING, OTHER } = CHARGE_TYPE

export function mapV4ChargesToOrderCost (charges: Charge[]): ?OrderCost {
  if (charges.length === 0) {
    return null
  }

  const currency = charges[0].totalCost.currency
  const total = calcTotalCost(charges)
  const items = calcCost(charges, ITEM)
  const shipping = calcCost(charges, SHIPPING)
  const refunds = calcRefunds(charges)
  const other = calcCost(charges, OTHER)
  const tax = calcTotalTax(charges)
  const adjustedTotal = total + other - refunds

  return {
    total: formatTotal(total, currency),
    items: formatCost({ amount: items.toString(), currency }),
    shipping: formatCost({ amount: shipping.toString(), currency }),
    tax: formatCost({ amount: tax.toString(), currency }),
    refunds: formatCost({ amount: refunds.toString(), currency }),
    adjustedTotal: formatCost({ amount: adjustedTotal.toString(), currency })
  }
}

function calcTotalCost (charges: Charge[]): number {
  const totalCosts = charges
    .filter(charge => Number(charge.totalCost.amount) > 0)
    .map(charge => charge.totalCost)

  return combineCosts(totalCosts)
}

function calcTotalTax (charges: Charge[]): number {
  const taxCosts = charges
    .filter(charge => Number(charge.totalTax.amount) > 0)
    .map(charge => charge.totalTax)

  return combineCosts(taxCosts)
}

function calcRefunds (charges: Charge[]): number {
  const refundCosts = charges
    .filter(charge => Number(charge.totalCost.amount) < 0)
    .map(charge => charge.totalCost)

  return Math.abs(combineCosts(refundCosts))
}

function formatTotal (total: number, currencyCode: string): string {
  const priceWithCurrency = formatCost({ amount: total.toString(), currency: currencyCode })

  return currencyCode === CURRENCY_CODE.USD
    ? priceWithCurrency + ' (USD)'
    : priceWithCurrency
}

function calcCost (charges: Charge[], chargeType: ChargeType): number {
  const allItemCosts = charges.reduce((itemCostAcc, charge) => {
    const orderItemCosts = charge.items
      .filter(item => item.chargeType === chargeType)
      .map(charge => charge.cost)

    return itemCostAcc.concat(...orderItemCosts)
  }, [])

  return combineCosts(allItemCosts)
}

import { motion } from 'motion/react'
import { Link, useLocation } from 'react-router-dom'
import { PhotoIcon } from '@heroicons/react/20/solid'

import EmptyState from '../../EmptyState'
import { QUERY_PARAMS } from '../../../constants'
import { hasImageFilterParamsInSearch } from '../../ImageLibraryActions/helpers/hasImageFilterParamsInSearch.helper'

export function ImageListEmpty() {
  const { search, pathname } = useLocation()

  const hasFiltersApplied = hasImageFilterParamsInSearch(search)

  if (hasFiltersApplied) {
    const searchParamsForClearSearch = new URLSearchParams(search)
    searchParamsForClearSearch.delete(QUERY_PARAMS.IMAGE_LIBRARY.SEARCH)
    searchParamsForClearSearch.delete(QUERY_PARAMS.IMAGE_LIBRARY.ORIENTATION)
    searchParamsForClearSearch.delete(QUERY_PARAMS.IMAGE_LIBRARY.FILE_FORMAT)
    searchParamsForClearSearch.delete(QUERY_PARAMS.IMAGE_LIBRARY.FILE_TYPE)
    searchParamsForClearSearch.delete(QUERY_PARAMS.IMAGE_LIBRARY.TAG)
    searchParamsForClearSearch.delete(QUERY_PARAMS.IMAGE_LIBRARY.TYPE)
    const urlWithNoImageLibraryParams = `${pathname}?${searchParamsForClearSearch.toString()}`

    return (
      <div className="mx-auto max-w-5xl">
        <div className="mt-16 text-center">No images found using selected filters</div>

        <motion.div
          animate={{ y: 0, opacity: 1 }}
          initial={{ y: -10, opacity: 0 }}
          className="mt-6"
          transition={{ delay: 0.1 }}
        >
          <Link className="btn btn-secondary mx-auto w-fit" to={urlWithNoImageLibraryParams}>
            Clear all filters
          </Link>
        </motion.div>
      </div>
    )
  }

  return (
    <EmptyState className="mt-12 h-[calc(100%-200px)] border-none py-8 md:py-8">
      <div className="mx-auto flex h-full max-w-5xl flex-col items-center justify-center">
        <PhotoIcon className="h-32 w-32 text-gray-200" />
        <h2 className="mt-4 font-normal">Your image library is empty</h2>
        <div className="mt-4">All uploaded images will appear here</div>

        <div className="mt-16 text-sm text-gray-700">
          We accept jpg and png formats, and prefer RGB files. The bigger the better.
        </div>
      </div>
    </EmptyState>
  )
}

import IconCross from '../../IconCross'
import { AnimatePresence, motion } from 'motion/react'

export function AppliedTags({ tags, onRemoveTag }: { tags: string[]; onRemoveTag: (tag: string) => void }) {
  return (
    <AnimatePresence initial={false}>
      {tags.length > 0 && (
        <motion.ul
          initial={{ height: 0 }}
          animate={{ height: 'auto' }}
          exit={{ height: 0 }}
          transition={{ type: 'tween', duration: 0.15 }}
          className="relative mt-8 flex max-w-full flex-wrap gap-2 overflow-auto text-sm"
        >
          <AnimatePresence initial={false}>
            {tags.map((tag) => (
              <TagPill key={tag} tag={tag} onClearTag={() => onRemoveTag(tag)} />
            ))}
          </AnimatePresence>
        </motion.ul>
      )}
    </AnimatePresence>
  )
}

function TagPill({ tag, onClearTag }: { tag: string; onClearTag: () => void }) {
  return (
    <motion.li
      animate={{ opacity: 1, scale: 1 }}
      className="flex min-w-fit gap-0.5 text-white"
      exit={{ opacity: 0, scale: 0 }}
      initial={{ opacity: 0, scale: 0 }}
      transition={{ duration: 0.15 }}
      layout
    >
      <span className="flex items-center rounded-l-lg bg-teal-500 px-5 py-2">
        <span className="overflow-auto text-sm font-normal">{tag}</span>
      </span>
      <motion.button
        className="rounded-r-lg bg-teal-500 px-3 py-2"
        variants={{
          tap: {
            scale: 0.9
          }
        }}
        whileHover={'hover'}
        whileTap={'tap'}
        onClick={(e) => {
          e.preventDefault()
          onClearTag()
        }}
      >
        <IconCross
          className="h-5 w-5"
          variants={{
            hover: {
              scale: 1.15
            }
          }}
        />
      </motion.button>
    </motion.li>
  )
}

import { useEffect } from 'react'
import { motion } from 'motion/react'
import { Link, useLocation } from 'react-router-dom'

import NotFound from '../../NotFound'
import SupportLink from '../../SupportLink'
import ClipboardCopy from '../../ClipboardCopy'
import { QUERY_PARAMS, ROUTE_PATHS } from '../../../constants'
import { FetchErrorInterface, OrderDetailDataErrorInterface } from '../../../interfaces'

/* -------------------------------------------------------------------------- */
/*                               Main component                               */
/* -------------------------------------------------------------------------- */
export function OrderDetailError({
  error,
  orderId
}: {
  error: FetchErrorInterface<OrderDetailDataErrorInterface>
  orderId: string
}) {
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)

  useEffect(() => {
    if (error.status === 404 && orderId) {
      window.analytics.track('Order Not Found', { orderId })
    }
  }, [error.status, orderId])

  const isWaitingForOrderToBeFulfilled = searchParams.get(QUERY_PARAMS.ORDER_DETAIL.SUCCESS) === 'true'

  if (error.status === 404 && isWaitingForOrderToBeFulfilled) {
    return <OrderDetailWaitingToBeFulfilled orderId={orderId} />
  } else if (error.status === 404) {
    return <OrderDetailNotFound />
  } else {
    return <OrderDetailUnknownError error={error} orderId={orderId} />
  }
}

/* -------------------------------------------------------------------------- */
/*                               Pure components                              */
/* -------------------------------------------------------------------------- */
function OrderDetailWaitingToBeFulfilled({ orderId }: { orderId: string }) {
  return (
    <div className="grid min-h-screen place-content-center p-8">
      <motion.div
        animate={{ scale: 1, opacity: 1 }}
        className="max-w-md rounded-2xl border-2 border-gray-600 px-6 py-12"
        data-test="page-not-found"
        initial={{ scale: 0.8, opacity: 0 }}
        transition={{ type: 'tween' }}
      >
        <h2 className="m-0 mb-8 break-words text-center">Order complete</h2>
        <p className="break-words text-center">Your order is being created and will appear shortly.</p>
        <p className="mt-8 text-center">Order reference: {orderId}</p>
        <Link className="btn btn-primary mx-auto mt-8 max-w-xs" to={`${ROUTE_PATHS.ORDERS}`}>
          View all orders
        </Link>
      </motion.div>
    </div>
  )
}

function OrderDetailNotFound() {
  return (
    <div className="grid min-h-screen place-content-center p-8">
      <NotFound>
        <Link className="btn btn-primary mx-auto mt-8 max-w-xs" to={`${ROUTE_PATHS.ORDERS}`}>
          Back to orders
        </Link>
      </NotFound>
    </div>
  )
}

function OrderDetailUnknownError({
  error,
  orderId
}: {
  error: FetchErrorInterface<OrderDetailDataErrorInterface>
  orderId: string
}) {
  const errorCode = error.responseBodyJson?.traceParent ?? error.status ?? '0'

  return (
    <div className="grid min-h-screen place-content-center p-8" data-test="error-message">
      <NotFound
        heading={`An error occurred while loading order ${orderId}`}
        body={
          <>
            <p className="text-center">
              Please try refreshing the page or <SupportLink>contact us</SupportLink> if the issue persists.
            </p>
            <div className="mt-4 break-words text-center text-gray-600">
              Error code: {errorCode}
              <ClipboardCopy className="align-middle" text={errorCode.toString()} showText={false} />
            </div>
          </>
        }
      >
        <Link className="btn btn-primary mx-auto mt-8 max-w-xs" to={ROUTE_PATHS.ORDERS}>
          Back to orders
        </Link>
      </NotFound>
    </div>
  )
}

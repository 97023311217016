import toast from 'react-hot-toast'
import { motion } from 'motion/react'
import { useEffect, useState } from 'react'

import {
  SALES_CHANNELS_STORE_PRODUCT_FULFILLMENT_UPDATE_ERROR_TOAST_ID,
  SALES_CHANNELS_STORE_PRODUCT_FULFILLMENT_UPDATE_SUCCESS_TOAST_ID
} from '../constants'
import Button from '../../Button'
import RadioGroup from '../../RadioGroup'
import { createToast } from '../../Toast'
import { StatusEnum } from '../../../enums'
import OverlayPortal from '../../OverlayPortal'
import { useMerchantService } from '../../../hooks'
import { FetchErrorInterface } from '../../../interfaces'
import { updateSalesChannelDetails } from '../../../helpers'

const PRODUCT_FULFILLMENT = [
  { value: 'true', label: 'Automatically fulfill new products with Prodigi' },
  { value: 'false', label: 'Ignore new products (do not fulfill with Prodigi)' }
]

export function UpdateSalesChannelDefaultProductFulfillmentStep({
  newSalesChannelId,
  onStepAdvance
}: {
  newSalesChannelId: string
  onStepAdvance: () => void
}) {
  const { merchantDetails, mutateMerchantDetails } = useMerchantService()

  const newSalesChannel = merchantDetails?.salesChannels.find((salesChannel) => salesChannel.id === newSalesChannelId)

  const [updateSalesChannelProductFulfillmentStatus, setUpdateSalesChannelProductFulfillmentStatus] =
    useState<StatusEnum>(StatusEnum.Idle)
  const [salesChannelProductFulfillment, setSalesChannelProductFulfillment] = useState(
    newSalesChannel?.defaultFulfillment ?? true
  )

  useEffect(() => {
    return () => {
      toast.dismiss(SALES_CHANNELS_STORE_PRODUCT_FULFILLMENT_UPDATE_ERROR_TOAST_ID)
    }
  }, [])

  async function handleSubmit() {
    try {
      if (!merchantDetails) {
        throw Error('No merchant details')
      }
      if (!newSalesChannel) {
        throw Error('No new sales channel')
      }

      if (salesChannelProductFulfillment === newSalesChannel.defaultFulfillment) {
        onStepAdvance()
        return
      }

      toast.dismiss(SALES_CHANNELS_STORE_PRODUCT_FULFILLMENT_UPDATE_ERROR_TOAST_ID)
      toast.dismiss(SALES_CHANNELS_STORE_PRODUCT_FULFILLMENT_UPDATE_SUCCESS_TOAST_ID)
      setUpdateSalesChannelProductFulfillmentStatus(StatusEnum.Loading)

      await updateSalesChannelDetails({
        merchantId: merchantDetails.id,
        packingSlipImageId: null,
        salesChannelId: newSalesChannelId,
        salesChannelName: newSalesChannel.description,
        defaultFulfillment: salesChannelProductFulfillment
      })
      await mutateMerchantDetails()

      setUpdateSalesChannelProductFulfillmentStatus(StatusEnum.Success)

      window.analytics.track('Store setup product fulfillment set')
      onStepAdvance()
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<unknown>
      createToast({
        content: `Please try again later`,
        duration: Infinity,
        footer: `${errorResponse.message} (Code ${errorResponse.status ?? '0'})`,
        heading: 'Failed to update',
        id: SALES_CHANNELS_STORE_PRODUCT_FULFILLMENT_UPDATE_ERROR_TOAST_ID,
        type: 'error-with-close'
      })
      setUpdateSalesChannelProductFulfillmentStatus(StatusEnum.Error)
    }
  }

  if (!newSalesChannel) {
    throw Error('New sales channel not found')
  }

  return (
    <motion.div animate={{ opacity: 1 }} exit={{ opacity: 0 }} initial={{ opacity: 0 }}>
      <form
        className="flex min-h-[150px] flex-col"
        onSubmit={(event) => {
          event.preventDefault()
          handleSubmit()
        }}
      >
        <RadioGroup
          ariaLabel="Sales channel product fulfillment config"
          className="mt-2"
          items={PRODUCT_FULFILLMENT}
          radioGroupItemClassName="text-black items-center"
          selectedValue={salesChannelProductFulfillment.toString()}
          onChange={(selection) => setSalesChannelProductFulfillment(selection === 'true')}
        />

        <div className="mt-auto flex justify-end pt-2">
          <Button isLoading={updateSalesChannelProductFulfillmentStatus === StatusEnum.Loading} type="submit">
            Next
          </Button>
        </div>
      </form>

      {updateSalesChannelProductFulfillmentStatus === StatusEnum.Loading && <OverlayPortal />}
    </motion.div>
  )
}

import { motion } from 'motion/react'
import { ReactNode, useEffect, useMemo } from 'react'
import { useLocation, useHistory } from 'react-router'

import { ImageLibraryFilterHeader } from '.'
import { OnChangeFilterType } from '../types'

interface ImageLibraryFilterContainerPropsInterface {
  children: ReactNode
  queryParam: string
  title: string
  onChangeFilter: OnChangeFilterType
}

export function ImageLibraryFilterContainer({
  children,
  queryParam,
  title,
  onChangeFilter
}: ImageLibraryFilterContainerPropsInterface) {
  const { search, pathname } = useLocation()
  const history = useHistory()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  function handleClear() {
    const newSearchParams = new URLSearchParams(searchParams)
    newSearchParams.delete(queryParam)
    history.push({ pathname, search: newSearchParams.toString() })
  }

  useEffect(() => {
    window.analytics.track(`Clicked Filter By ${title}`)
  }, [title])

  return (
    <motion.div
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 5 }}
      initial={{ opacity: 0, x: 5 }}
      transition={{ type: 'tween', duration: 0.1 }}
    >
      <ImageLibraryFilterHeader
        showClearAction={searchParams.has(queryParam)}
        title={title}
        onBack={() => onChangeFilter(null)}
        onClear={handleClear}
      />
      {children}
    </motion.div>
  )
}

import { motion } from 'motion/react'
import { Link, useLocation } from 'react-router-dom'

export function EmptyState({ searchingFor, searchParam }: { searchingFor: string; searchParam?: string }) {
  const { search, pathname } = useLocation()

  const hasFilterApplied = searchParam && search.includes(searchParam)

  if (hasFilterApplied) {
    const searchParamsForClearSearch = new URLSearchParams(search)
    searchParamsForClearSearch.delete(searchParam)
    const urlWithNoSearchParam = `${pathname}?${searchParamsForClearSearch.toString()}`

    return (
      <div className="mx-auto max-w-[250px] pb-4 pt-6 text-center">
        <span>No {searchingFor} found</span>

        <motion.div
          animate={{ y: 0, opacity: 1 }}
          initial={{ y: -10, opacity: 0 }}
          className="mt-6"
          transition={{ delay: 0.1 }}
        >
          <Link className="btn btn-secondary mx-auto w-fit" to={urlWithNoSearchParam}>
            Clear search
          </Link>
        </motion.div>
      </div>
    )
  }

  return <div className="mx-auto max-w-[250px] pb-4 pt-6 text-center">No {searchingFor} available</div>
}

import { motion } from 'motion/react'
import EmptyState from '../../EmptyState'

export function OrdersEmptyState({ children, title }: { children: React.ReactNode; title: string }) {
  return (
    <motion.div animate={{ opacity: 1 }} className="tailwind" initial={{ opacity: 0 }}>
      <EmptyState>
        <div className="mx-auto max-w-2xl flex-col justify-center">
          <h2 className="m-0 font-normal md:text-lg">{title}</h2>
          {children}
        </div>
      </EmptyState>
    </motion.div>
  )
}

import IconCross from '../../IconCross'
import { AnimatePresence, motion } from 'motion/react'

export function ProductFilterPill({ value, onClear }: { value: string | null; onClear: () => void }) {
  return (
    <AnimatePresence initial={false}>
      {Boolean(value) && (
        <motion.div
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0 }}
          className="flex"
        >
          <span className="flex items-center whitespace-nowrap rounded-l-lg border border-gray-200 bg-gray-100 px-4 py-2">
            {value}
          </span>
          <motion.button
            className="ml-1 rounded-r-lg border border-gray-200 bg-gray-100 px-4 py-2"
            variants={{
              tap: {
                scale: 0.85
              }
            }}
            whileHover="hover"
            whileTap="tap"
            onClick={onClear}
          >
            <IconCross
              className="h-5 w-5"
              variants={{
                hover: {
                  scale: 1.25
                }
              }}
            />
            <span className="sr-only">Clear {value}</span>
          </motion.button>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

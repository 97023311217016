import { motion } from 'motion/react'

import LogoProdigi from '../../LogoProdigi'

export function Mockups3DPreviewLoading() {
  return (
    <div className="grid h-80vh w-full place-content-center">
      <div className="h-full w-full flex-row items-center justify-center">
        <motion.div
          className="flex justify-center"
          initial={{ opacity: 0.02 }}
          animate={{ opacity: [0.02, 0.2, 0.02] }}
          transition={{
            duration: 1.75,
            repeat: Infinity,
            repeatDelay: 0.1
          }}
        >
          <LogoProdigi className="h-[60px] w-auto" type="logo-mark" />
        </motion.div>
        <div className="mt-4 text-gray-300">Generating mockup</div>
      </div>
    </div>
  )
}

import { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid'

import { APP_ENVIRONMENTS, QUERY_PARAMS } from '../../../constants'
import { DropdownMenu, DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuTrigger } from '../../DropdownMenu'

type ImageLibrarySortValueType = '+date_created' | '-date_created' | '-date_last_used'

export const DEFAULT_IMAGE_LIBRARY_SORT_ORDER: ImageLibrarySortValueType = '-date_last_used'

const IMAGE_LIBRARY_SORT_OPTIONS: ImageLibrarySortValueType[] = ['-date_created', '+date_created', '-date_last_used']

export function ImageLibrarySort() {
  const { search, pathname } = useLocation()
  const history = useHistory()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  const selectedSortParam = searchParams.get(QUERY_PARAMS.IMAGE_LIBRARY.ORDER_BY)
  const selectedSortValue = useMemo<ImageLibrarySortValueType>(
    () => (selectedSortParam as ImageLibrarySortValueType) || DEFAULT_IMAGE_LIBRARY_SORT_ORDER,
    [selectedSortParam]
  )
  const [selectedSortOption, setSelectedSortOption] = useState<ImageLibrarySortValueType>(selectedSortValue)

  // Ensures URL changes are captured when using browser navigation buttons
  useEffect(() => {
    setSelectedSortOption(selectedSortValue)
  }, [selectedSortValue, searchParams])

  const updateSelectedSortOption = (selected: ImageLibrarySortValueType) => {
    setSelectedSortOption(selected)
    const newSearchParams = new URLSearchParams(searchParams)
    newSearchParams.set(QUERY_PARAMS.IMAGE_LIBRARY.ORDER_BY, selected)
    history.push({ pathname, search: newSearchParams.toString() })
  }

  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger>
        <ChevronUpDownIcon className="mr-2 h-7 w-7 text-gray-500" />
        <span>{formatSortOptionName(selectedSortOption)}</span>
      </DropdownMenuTrigger>

      <DropdownMenuContent align="end">
        {IMAGE_LIBRARY_SORT_OPTIONS.map((option) => (
          <DropdownMenuCheckboxItem
            checked={selectedSortOption === option}
            key={option}
            onSelect={() => {
              updateSelectedSortOption(option)
            }}
          >
            {formatSortOptionName(option)}
          </DropdownMenuCheckboxItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

function formatSortOptionName(sortOption: ImageLibrarySortValueType) {
  switch (sortOption) {
    case '+date_created':
      return 'Oldest'
    case '-date_created':
      return 'Newest'
    case '-date_last_used':
      return 'Recently used'
    default:
      const unhandledOption: never = sortOption
      if (process.env.REACT_APP_ENV === APP_ENVIRONMENTS.TEST) {
        console.warn(`Unhandled sort option ${unhandledOption} for formatting found. Add a new case to fix this.`)
      }
      return sortOption
  }
}

import { motion } from 'motion/react'
import { Link } from 'react-router-dom'

import WhitePanel from '../../WhitePanel'
import { ROUTE_PATHS } from '../../../constants'

export function PaymentError({ errorMessage, orderDetailLink }: { errorMessage: string; orderDetailLink: string }) {
  return (
    <motion.div
      animate={{ opacity: 1 }}
      className="flex h-full w-full flex-col items-center justify-center"
      initial={{ opacity: 0 }}
    >
      <WhitePanel className="max-w-5xl" heading="Payment failed">
        <p style={{ margin: 0 }}>{errorMessage}</p>

        <div className="mt-10 flex w-full flex-col gap-3 sm:flex-row">
          <Link className="btn btn-primary" to={ROUTE_PATHS.SETTINGS.BILLING}>
            Update billing details
          </Link>
          <Link className="btn btn-secondary" to={orderDetailLink}>
            I&apos;ll do it later
          </Link>
        </div>
      </WhitePanel>
    </motion.div>
  )
}

import { useLocation } from 'react-router'
import { useHotkeys } from '@mantine/hooks'
import * as Popover from '@radix-ui/react-popover'
import { AnimatePresence, motion } from 'motion/react'
import React, { useEffect, useMemo, useRef, useState } from 'react'

import {
  ImageLibraryFilterActiveCount,
  ImageLibraryFilterContainer,
  ImageLibraryFilterSelection,
  ImageLibraryListAction,
  ImageLibraryListActionFileFormat
} from '.'
import { useSplitToggle } from '../../../hooks'
import { QUERY_PARAMS } from '../../../constants'
import { ImageLibraryFiltersType } from '../types'
import { Bars3Icon } from '@heroicons/react/24/outline'
import { HOTKEYS } from '../../../constants/hotkeys.const'
import { PORTAL_ID } from '../../../constants/portalId.const'
import { FEATURE_NAMES } from '../../../../split-io/feature-names'
import { ImageLibraryListActionTag } from './ImageLibraryListActionTag.component'

const ORIENTATION_OPTIONS = [
  {
    content: <span className="pr-10">Portrait</span>,
    value: 'portrait',
    searchLabel: 'Portrait'
  },
  {
    content: <span className="pr-10">Landscape</span>,
    value: 'landscape',
    searchLabel: 'Landscape'
  },
  {
    content: <span className="pr-10">Square</span>,
    value: 'square',
    searchLabel: 'Square'
  }
]
export function ImageLibraryFilter() {
  const { search } = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])
  const [isOpen, setIsOpen] = useState(false)
  const popoverTriggerRef = useRef<HTMLButtonElement | null>(null)
  const [openedFilter, setOpenedFilter] = useState<ImageLibraryFiltersType>(null)
  const [selectedFilters, setSelectedFilters] = useState<ImageLibraryFiltersType[]>([])

  const { splitIsOn: isPDFSplitOn } = useSplitToggle({ toggle: FEATURE_NAMES.IMAGE_LIBRARY_PDF })
  const { splitIsOn: isBrandingSettingsOn } = useSplitToggle({ toggle: FEATURE_NAMES.BRANDING_SETTINGS })

  useEffect(() => {
    const selectedFiltersFromQueryParams: ImageLibraryFiltersType[] = []
    if (searchParams.get(QUERY_PARAMS.IMAGE_LIBRARY.TYPE)) {
      selectedFiltersFromQueryParams.push('type')
    }
    if (
      searchParams.get(QUERY_PARAMS.IMAGE_LIBRARY.FILE_FORMAT) ||
      searchParams.get(QUERY_PARAMS.IMAGE_LIBRARY.FILE_TYPE)
    ) {
      selectedFiltersFromQueryParams.push('file-format')
    }
    if (searchParams.get(QUERY_PARAMS.IMAGE_LIBRARY.ORIENTATION)) {
      selectedFiltersFromQueryParams.push('orientation')
    }
    if (searchParams.get(QUERY_PARAMS.IMAGE_LIBRARY.TAG)) {
      selectedFiltersFromQueryParams.push('tag')
    }
    setSelectedFilters(selectedFiltersFromQueryParams)
  }, [searchParams])

  function handleHotKeyFilter(filter: NonNullable<ImageLibraryFiltersType>) {
    setOpenedFilter(filter)
    if (!isOpen) {
      popoverTriggerRef.current?.click()
    }
  }

  useHotkeys([
    [HOTKEYS.IMAGE_LIBRARY.TYPE.TRIGGER, () => handleHotKeyFilter('type')],
    [HOTKEYS.IMAGE_LIBRARY.FILE_FORMAT.TRIGGER, () => handleHotKeyFilter('file-format')],
    [HOTKEYS.IMAGE_LIBRARY.ORIENTATION.TRIGGER, () => handleHotKeyFilter('orientation')],
    [HOTKEYS.IMAGE_LIBRARY.TAG.TRIGGER, () => handleHotKeyFilter('tag')]
  ])

  let typeOptions = [
    {
      content: <span className="pr-10">Artwork</span>,
      value: 'artwork',
      searchLabel: 'Artwork'
    },
    {
      content: <span className="pr-10">{isBrandingSettingsOn ? 'Insert' : 'Packing slip'}</span>,
      value: 'packing',
      searchLabel: isBrandingSettingsOn ? 'Insert' : 'Packing slip'
    },
    {
      content: <span className="pr-10">Book</span>,
      value: 'book',
      searchLabel: 'Book'
    }
  ]

  if (!isPDFSplitOn) {
    typeOptions = typeOptions.filter((option) => option.value !== 'book')
  }

  return (
    <Popover.Root
      onOpenChange={(open) => {
        setIsOpen(open)
        // Ensures we start with selector component when re-opening the popover
        if (!open) {
          setOpenedFilter(null)
        }
      }}
    >
      <Popover.Trigger
        className="relative h-full w-full cursor-pointer truncate rounded-lg border border-gray-200 bg-white px-3 py-2 text-left outline-offset-1 outline-purple-500"
        data-test="image-library-list-actions__filters"
        ref={popoverTriggerRef}
      >
        <span className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <Bars3Icon className="h-7 w-7 text-gray-500" aria-hidden="true" />
        </span>
        <span className="ml-9">Filter by</span>
        <ImageLibraryFilterActiveCount activeFilterCount={selectedFilters.length} />
      </Popover.Trigger>

      <AnimatePresence>
        {isOpen && (
          <Popover.Portal container={document.getElementById(PORTAL_ID)} forceMount>
            <Popover.Content asChild align="end" sideOffset={2}>
              <div style={{ perspective: '2000px', transformStyle: 'preserve-3d' }}>
                <motion.div
                  animate={{ opacity: 1, rotateX: '0deg' }}
                  className="max-w-screen min-w-[var(--prodigi-order-filters-min-width)] overflow-auto rounded-lg border bg-white text-input shadow-lg sm:min-w-[240px] sm:text-base"
                  exit={{ opacity: 0, rotateX: '-15deg' }}
                  initial={{ opacity: 0, rotateX: '-15deg' }}
                  style={
                    {
                      transformOrigin: 'center -45%',
                      transformStyle: 'preserve-3d',
                      '--prodigi-order-filters-min-width': popoverTriggerRef.current?.offsetWidth
                        ? `${popoverTriggerRef.current?.offsetWidth}px`
                        : '240px'
                    } as React.CSSProperties
                  }
                  transition={{ duration: 0.15 }}
                >
                  <AnimatePresence initial={false} mode="wait">
                    {!openedFilter && (
                      <ImageLibraryFilterSelection
                        key="filters"
                        selectedFilters={selectedFilters}
                        onChangeFilter={setOpenedFilter}
                      />
                    )}

                    {openedFilter === 'type' && (
                      <ImageLibraryFilterContainer
                        key="type"
                        queryParam={QUERY_PARAMS.IMAGE_LIBRARY.TYPE}
                        title="Type"
                        onChangeFilter={setOpenedFilter}
                      >
                        <ImageLibraryListAction
                          title="type"
                          options={typeOptions}
                          queryParam={QUERY_PARAMS.IMAGE_LIBRARY.TYPE}
                        />
                      </ImageLibraryFilterContainer>
                    )}

                    {openedFilter === 'file-format' && (
                      <ImageLibraryFilterContainer
                        key="file-format"
                        queryParam={
                          isPDFSplitOn ? QUERY_PARAMS.IMAGE_LIBRARY.FILE_TYPE : QUERY_PARAMS.IMAGE_LIBRARY.FILE_FORMAT
                        }
                        title="File format"
                        onChangeFilter={setOpenedFilter}
                      >
                        <ImageLibraryListActionFileFormat />
                      </ImageLibraryFilterContainer>
                    )}

                    {openedFilter === 'orientation' && (
                      <ImageLibraryFilterContainer
                        key="orientation"
                        queryParam={QUERY_PARAMS.IMAGE_LIBRARY.ORIENTATION}
                        title="Orientation"
                        onChangeFilter={setOpenedFilter}
                      >
                        <ImageLibraryListAction
                          title="orientation"
                          options={ORIENTATION_OPTIONS}
                          queryParam={QUERY_PARAMS.IMAGE_LIBRARY.ORIENTATION}
                        />
                      </ImageLibraryFilterContainer>
                    )}

                    {openedFilter === 'tag' && (
                      <ImageLibraryFilterContainer
                        key="tag"
                        queryParam={QUERY_PARAMS.IMAGE_LIBRARY.TAG}
                        title="Tagged"
                        onChangeFilter={setOpenedFilter}
                      >
                        <ImageLibraryListActionTag />
                      </ImageLibraryFilterContainer>
                    )}
                  </AnimatePresence>
                </motion.div>
              </div>
            </Popover.Content>
          </Popover.Portal>
        )}
      </AnimatePresence>
    </Popover.Root>
  )
}

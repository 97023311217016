import { motion } from 'motion/react'
import { Dispatch, MouseEvent, ReactNode } from 'react'

import Button from '../../Button'
import RadioGroup from '../../RadioGroup'
import { RegisterReducerActionType } from '../types'
import { RegisterReducerActionTypeEnum } from '../enums'
import { RegisterFormStringFieldInterface } from '../interfaces'

interface PropTypesInterface {
  children?: ReactNode
  marketingPriorityToday: RegisterFormStringFieldInterface
  onStepChange: (event: MouseEvent<HTMLAnchorElement | HTMLButtonElement>, step: number) => void
  registerFormDispatch: Dispatch<RegisterReducerActionType>
}

const PRIORITY_ANSWERS = [
  { value: 'Place an order', label: 'Place an order' },
  {
    value: 'Find pricing and shipping info',
    label: 'Find pricing and shipping info'
  },
  {
    value: 'Just explore',
    label: 'Just explore'
  }
]

export default function Step3({
  children,
  marketingPriorityToday,
  onStepChange,
  registerFormDispatch
}: PropTypesInterface) {
  return (
    <motion.form
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="flex min-h-full w-full flex-col"
    >
      <div className="flex flex-col">
        <span className="flex items-center justify-between">
          <span className="text-sm uppercase text-gray-600">I would like to</span>
        </span>

        <RadioGroup
          ariaLabel="Today I would like to"
          className="mt-2"
          items={PRIORITY_ANSWERS}
          otherOptionConfig={{ placeholder: 'Tell us more...', maxLength: 100 }}
          selectedValue={marketingPriorityToday?.value}
          showOtherOptionInput
          onChange={(selection, error) => {
            registerFormDispatch({
              type: RegisterReducerActionTypeEnum.SetMarketingPriorityToday,
              payload: {
                marketingPriorityToday: selection,
                error
              }
            })
          }}
        />
        <span className="mt-1 text-right text-sm text-red-500">
          <motion.span
            animate={marketingPriorityToday.error ? { opacity: 1 } : { opacity: 0 }}
            initial={{ opacity: 0 }}
          >
            {marketingPriorityToday.error}&nbsp;
          </motion.span>
        </span>

        <Button
          type="submit"
          className="mt-12"
          disabled={Boolean(marketingPriorityToday.error)}
          onClick={(event) => {
            onStepChange(event, 4)
          }}
        >
          Continue
        </Button>

        {children}
      </div>
    </motion.form>
  )
}

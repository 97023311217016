import { motion } from 'motion/react'
import { FormEvent, useState } from 'react'
import { Link } from 'react-router-dom'
import { isEmailValid } from '../../../helpers'
import { fetcher } from '../../helpers'
import Button from '../Button'
import RightPanel from '../Login/components/RightPanel.component'
import TextField from '../TextField'
import { selectPwintyApiSettings } from '../../selectors/appSettings'
import { StatusEnum } from '../../enums'

export default function PasswordReset() {
  const [passwordResetStatus, setPasswordResetStatus] = useState<StatusEnum>(StatusEnum.Idle)
  const [emailErrorMessage, setEmailErrorMessage] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [email, setEmail] = useState('')

  async function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()
    setEmailErrorMessage(null)
    if (!isEmailValid(email)) {
      setEmailErrorMessage('Please enter a valid email address.')
      return
    }
    setPasswordResetStatus(StatusEnum.Loading)
    const endpoint = selectPwintyApiSettings().endpoint
    try {
      await fetcher(endpoint + '/api/Account/RequestResetPassword', {
        body: `Email=${encodeURIComponent(email)}`,
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      })
      setPasswordResetStatus(StatusEnum.Success)
    } catch (error) {
      setError('Email address not found.')
      setPasswordResetStatus(StatusEnum.Error)
    }
  }

  return (
    <div className="mx-auto flex min-h-screen max-w-[1440px] text-lg">
      <div className="relative mx-auto flex min-h-screen w-full flex-col p-14 pt-10 lg:w-1/2 lg:p-36 lg:pt-16">
        <a href="https://www.prodigi.com" title="Prodigi" className="mb-16 block w-fit">
          <img
            src="/img/logo/prodigi-logo-dark.svg"
            className="h-[47] w-[120px] lg:h-[60] lg:w-[150px]"
            alt="Prodigi logo"
          />
        </a>

        <h2 className="m-0 mb-2 text-2xl leading-10 tracking-tight text-gray-900">Password reset</h2>

        <motion.div className="flex flex-col" animate={{ opacity: 1 }} initial={{ opacity: 0 }}>
          <motion.p
            animate={
              passwordResetStatus !== StatusEnum.Success ? { opacity: 1, height: 'auto' } : { opacity: 0, height: 0 }
            }
            initial={{ opacity: 1, height: 'auto' }}
          >
            Enter your email below to reset your password.{' '}
          </motion.p>

          <motion.span
            animate={
              passwordResetStatus === StatusEnum.Success ? { opacity: 1, height: 'auto' } : { opacity: 0, height: 0 }
            }
            initial={{ opacity: 0, height: 0 }}
          >
            We&apos;ve sent you an email containing a link to change your password.
          </motion.span>

          <motion.form
            animate={
              passwordResetStatus !== StatusEnum.Success
                ? { opacity: 1, height: 'auto' }
                : { opacity: 0, height: 0, pointerEvents: 'none' }
            }
            initial={{ opacity: 1, height: 'auto' }}
            onSubmit={onSubmit}
            id="reset-form"
            className="relative mt-12 flex flex-col"
          >
            <span className="flex items-center justify-between">
              <label className="text-sm uppercase text-gray-600" htmlFor="email">
                Email
              </label>
            </span>
            <span className="relative">
              <TextField
                dataTest="login-email-input"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
                id="email"
                name="email"
                type="text"
                required
              />
            </span>

            <motion.span
              className="mt-1 text-right text-sm text-red-500"
              animate={error ? { opacity: 1, height: 'auto' } : { opacity: 0, height: 0 }}
              initial={{ opacity: 0, height: 0 }}
            >
              {error}
            </motion.span>

            <motion.span
              className="mt-1 text-right text-sm text-red-500"
              animate={emailErrorMessage ? { opacity: 1 } : { opacity: 0 }}
              initial={{ opacity: 0 }}
            >
              {emailErrorMessage}&nbsp;
            </motion.span>

            <Button
              type="submit"
              isLoading={passwordResetStatus === StatusEnum.Loading}
              disabled={passwordResetStatus === StatusEnum.Loading || email === ''}
              className="mt-4"
              form="reset-form"
            >
              Reset password
            </Button>
          </motion.form>
        </motion.div>

        <div className="mt-12 md:mt-24">
          Remembered your password?{' '}
          <Link className="text-purple-500" to={`/login`} tabIndex={5}>
            Log in
          </Link>
        </div>
      </div>
      <RightPanel />
    </div>
  )
}

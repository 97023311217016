import { useEffect } from 'react'
import { motion } from 'motion/react'

import {
  LoadingOrderDetails,
  OrderDetailError,
  PaymentAuthBanner,
  OrderDetailAddress,
  OrderDetailCostSummary,
  OrderDetailPackingSlip,
  OrderDetailSummary,
  OrderDetailOrderHistory
} from './components'
import { OrderHistoryTypeEnum } from './enums'
import OrderEditDisplay from '../OrderEditDisplay'
import OrderDetailHeader from '../OrderDetailHeader'
import OrderPausedDisplay from '../OrderPausedDisplay'
import { FEATURE_NAMES } from '../../../split-io/feature-names'
import { useLogToInsightOps } from '../../hooks/useLogToInsightOps'
import OrderDetailShipmentDisplay from '../OrderDetailShipmentDisplay'
import { useOrderActions, useOrderDetail, useSplitToggle } from '../../hooks'
import { OrderDetailCSIssueInterface, OrderDetailInterface } from '../../interfaces'
import { AdditionalChargeInterface } from './components/OrderDetailCostSummary.component'
import { OrderDetailBrandedInserts } from './components/OrderDetailBrandedInserts.component'
import { OrderDetailIssueStatusEnum, OrderDetailIssueTypeEnum, OrderStatusEnum } from '../../enums'
import { OrderDetailStripeRedirectModal } from './components/OrderDetailStripeRedirectModal.component'

export default function OrderDetail({ orderId }: { orderId: string }) {
  const { orderDetailsResponse, error } = useOrderDetail(orderId)

  const orderDetails = orderDetailsResponse?.data.order

  if (error) {
    return <OrderDetailError error={error} orderId={orderId} />
  }

  if (!orderDetails) {
    return <LoadingOrderDetails />
  }

  if (orderDetails.status === OrderStatusEnum.DRAFT) {
    return <OrderEditDisplay />
  }

  if (orderDetails.status === OrderStatusEnum.ON_HOLD) {
    return <OrderPausedDisplay />
  }

  return <OrderDetailDisplay orderId={orderId} orderDetails={orderDetails} />
}

function OrderDetailDisplay({ orderId, orderDetails }: { orderId: string; orderDetails: OrderDetailInterface }) {
  const { logToInsightOps } = useLogToInsightOps()
  const { mutateOrderDetails } = useOrderDetail(orderId)
  const { orderActions, orderActionsFetchError, mutateOrderActions } = useOrderActions(orderId)
  const { splitIsOn: isBrandingSettingsOn } = useSplitToggle({ toggle: FEATURE_NAMES.BRANDING_SETTINGS })

  useEffect(() => {
    if (orderActionsFetchError && orderId) {
      logToInsightOps('Failed to fetch order actions', {
        props: {
          orderId,
          traceparent: orderActionsFetchError.responseBodyJson?.traceParent
        },
        level: 'error'
      })
    }
  }, [logToInsightOps, orderActionsFetchError, orderId])

  const canUpdateAddress = Boolean(orderActions?.data.updateRecipient.isAvailable)

  const hasPaymentAuthorisationIssue = Boolean(
    orderDetails.issues.find((issue) => issue.type === OrderDetailIssueTypeEnum.PaymentRequiresAuthorisation)
  )
  const cancellationIsAvailable = Boolean(orderActions?.data.cancel.isAvailable)

  const orderHistory = orderDetails.history ?? []

  const additionalCharges = orderHistory.filter(
    (historyItem) => historyItem.orderHistoryType === OrderHistoryTypeEnum.OrderAdditionalCharge
  ) as Array<AdditionalChargeInterface>

  const unresolvedIssues = orderDetails.issues
    .filter(
      (issue) =>
        issue.type === OrderDetailIssueTypeEnum.FulfillmentIssue &&
        Boolean(
          (issue.data as OrderDetailCSIssueInterface)?.status === OrderDetailIssueStatusEnum.InProgress ||
            (issue.data as OrderDetailCSIssueInterface)?.status === OrderDetailIssueStatusEnum.Open
        )
    )
    .map((issue) => (issue.data as OrderDetailCSIssueInterface)?.issueType)

  function handleOrderDetailMutate() {
    mutateOrderDetails()
    mutateOrderActions()
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="container mx-auto space-y-4 pb-36 pt-8 xl:space-y-8"
    >
      <OrderDetailHeader
        cancellationIsAvailable={cancellationIsAvailable}
        createdDate={orderDetails.createdDate}
        orderId={orderDetails.id}
        recipientName={orderDetails.recipient.name}
        status={orderDetails.status}
        unresolvedIssues={unresolvedIssues}
        mutateOrderDetails={handleOrderDetailMutate}
      />

      {hasPaymentAuthorisationIssue && <PaymentAuthBanner />}

      <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 xl:gap-8">
        <div className="row-span-full lg:col-span-2 lg:row-auto">
          <OrderDetailShipmentDisplay
            countryCode={orderDetails.recipient.address.countryCode}
            items={orderDetails.items}
            orderStatus={orderDetails.status}
            shipments={orderDetails.shipments}
            unallocatedItems={orderDetails.items.filter((item) => orderDetails.unallocatedItems.includes(item.id))}
          />
        </div>
        <div className="space-y-4 lg:row-span-3 xl:space-y-8">
          <OrderDetailSummary
            merchantReference={orderDetails.merchantReference}
            numberOfItems={orderDetails.costSummary.totalQuantityOfItems}
            recipientAddressCountryCode={orderDetails.recipient.address.countryCode}
            recipientName={orderDetails.recipient.name}
            salesChannelId={orderDetails.salesChannelId}
            totalCost={orderDetails.costSummary.totalCost}
          />
          <OrderDetailAddress
            mutateOrderDetails={handleOrderDetailMutate}
            orderId={orderDetails.id}
            orderRecipient={orderDetails.recipient}
            showEditButton={canUpdateAddress}
          />

          {isBrandingSettingsOn ? (
            <OrderDetailBrandedInserts />
          ) : (
            <OrderDetailPackingSlip
              packingSlipStatus={orderDetails.packingSlipStatus}
              packingSlipUrl={orderDetails.packingSlipUrl}
            />
          )}
        </div>
        <div className="lg:col-span-2">
          <OrderDetailCostSummary
            additionalCharges={additionalCharges}
            hasUnallocated={orderDetails.unallocatedItems.length !== 0}
            itemCost={orderDetails.costSummary.items}
            orderId={orderDetails.id}
            shippingCost={orderDetails.costSummary.shipping}
            taxCost={orderDetails.costSummary.tax}
            totalCost={orderDetails.costSummary.totalCost}
            usSalesTaxCollected={orderDetails.usSalesTaxCollected}
          />
        </div>

        <div className="lg:col-span-2">
          <OrderDetailOrderHistory history={orderHistory} />
        </div>
      </div>

      <OrderDetailStripeRedirectModal orderId={orderId} />
    </motion.div>
  )
}

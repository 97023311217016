import { HEADER_NAMES } from '../constants'
import { removeDuplicates } from '../../../helpers'

export const DEFAULT_COLUMN_ORDER = [
  HEADER_NAMES.SIZE,
  HEADER_NAMES.ORIENTATION,
  HEADER_NAMES.PAPER,
  HEADER_NAMES.SUBSTRATE_WEIGHT,
  HEADER_NAMES.STYLE,
  HEADER_NAMES.FRAME,
  HEADER_NAMES.FRAME_COLOUR,
  HEADER_NAMES.WRAP,
  HEADER_NAMES.EDGE,
  HEADER_NAMES.MOUNT,
  HEADER_NAMES.MOUNT_COLOUR,
  HEADER_NAMES.GLAZE,
  HEADER_NAMES.COLOURS,
  HEADER_NAMES.FINISH,
  HEADER_NAMES.BRAND,
  HEADER_NAMES.FULFILLED_FROM,
  HEADER_NAMES.SLA,
  HEADER_NAMES.SKU,
  HEADER_NAMES.PRICE
]

export function orderVariantTableColumns(parentCategory: { slug: string; title: string }) {
  switch (parentCategory.slug) {
    case 'technology/laptops-and-computers':
      return removeDuplicates([HEADER_NAMES.SIZE, HEADER_NAMES.SKU, ...DEFAULT_COLUMN_ORDER])
    case 'technology/phone-cases':
    case 'technology/tablet-cases':
    case 'technology/watch-straps':
      return removeDuplicates([HEADER_NAMES.BRAND, HEADER_NAMES.SIZE, ...DEFAULT_COLUMN_ORDER])
    default:
      return DEFAULT_COLUMN_ORDER
  }
}

import clsx from 'clsx'
import toast from 'react-hot-toast'
import { motion } from 'motion/react'
import { useEffect, useState } from 'react'
import { StarIcon } from '@heroicons/react/20/solid'

import styles from './SelectProduct.module.css'
import { updateMerchantPreferences } from '../../../../v2/helpers'
import { createErrorToast } from '../../../../v2/components/Toast'
import OverlayPortal from '../../../../v2/components/OverlayPortal'
import { FetchErrorInterface, StatusType } from '../../../../v2/interfaces'
import { generateNewSavedItems } from '../../../../v2/components/SavedItems'
import { MerchantPreferencesErrorInterface, useMerchantPreferences, useMerchantService } from '../../../../v2/hooks'

const FAVOURITE_TOAST_IDS = { ERROR: 'prodigiSkuFavouriteErrorToast' }

export function SaveItemToggle({ sku }: { sku: string }) {
  const { merchantPreferences, merchantPreferencesResponse, isLoadingMerchantPreferences } = useMerchantPreferences()

  useEffect(() => {
    return () => {
      toast.dismiss(FAVOURITE_TOAST_IDS.ERROR)
    }
  }, [])

  if (isLoadingMerchantPreferences || !merchantPreferences || !merchantPreferencesResponse) {
    return null
  }

  return (
    <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <SaveItemToggleLoaded key={merchantPreferencesResponse.traceParent} sku={sku} />
    </motion.span>
  )
}

function SaveItemToggleLoaded({ sku }: { sku: string }) {
  const { merchantDetails } = useMerchantService()
  const { merchantPreferences, mutateMerchantPreferences } = useMerchantPreferences()

  const [saveStatus, setSaveStatus] = useState<StatusType>('idle')
  const [isFavourite, setIsFavourite] = useState(
    () => merchantPreferences?.starredProducts.some((starredProduct) => starredProduct.skuName === sku) ?? false
  )

  async function handleFavouriteToggle(shouldFavourite: boolean) {
    setIsFavourite(shouldFavourite)
    toast.dismiss(FAVOURITE_TOAST_IDS.ERROR)
    setSaveStatus('loading')

    if (shouldFavourite) {
      window.analytics.track('Clicked: Save item', { sku })
    } else {
      window.analytics.track('Remove saved item', { sku })
    }

    try {
      if (!merchantPreferences) {
        throw Error('No merchant preferences')
      }
      await updateMerchantPreferences(
        {
          ...merchantPreferences,
          starredProducts: generateNewSavedItems({ merchantPreferences, sku, shouldFavourite })
        },
        merchantDetails?.id
      )
      await mutateMerchantPreferences()
      setSaveStatus('success')
    } catch (error) {
      const fetchErrorResponse = error as FetchErrorInterface<MerchantPreferencesErrorInterface>

      if (fetchErrorResponse.responseBodyJson?.failures?.['StarredProducts:MaxReached']) {
        createErrorToast({
          heading: 'Failed to update saved item',
          id: FAVOURITE_TOAST_IDS.ERROR,
          errorMessage: 'You have reached the maximum amount of saved items',
          errorCode: `${fetchErrorResponse.responseBodyJson?.traceParent ?? 0}-${fetchErrorResponse.status ?? 0}`
        })
      } else {
        createErrorToast({
          heading: 'Failed to update saved item',
          id: FAVOURITE_TOAST_IDS.ERROR,
          errorMessage: sku,
          errorCode: `${fetchErrorResponse.responseBodyJson?.traceParent ?? 0}-${fetchErrorResponse.status ?? 0}`
        })
      }

      setIsFavourite(!shouldFavourite)
      setSaveStatus('error')
    }
  }

  return (
    <>
      <button
        className={clsx(
          'ml-3 inline-block align-bottom transition-opacity',
          saveStatus === 'loading' && 'cursor-wait opacity-80',
          styles.favouriteToggle,
          isFavourite && styles.isFavourite
        )}
        disabled={saveStatus === 'loading'}
        onClick={() => handleFavouriteToggle(!isFavourite)}
      >
        <motion.div whileTap={{ scale: 0.8 }}>
          <StarIcon className={clsx('h-10 w-10 transition-colors', isFavourite ? 'text-[#FFD907]' : 'text-gray-200')} />
        </motion.div>
      </button>
      {saveStatus === 'loading' && <OverlayPortal className="cursor-wait" />}
    </>
  )
}

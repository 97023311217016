import { Link } from 'react-router-dom'

import Button from '../../Button'
import WhitePanel from '../../WhitePanel'
import { formatCost } from '../../../helpers/formatCost'
import { CostInterface } from '../../../interfaces/Cost.interface'
import { motion } from 'motion/react'

export function PaymentAuthRequest({
  cost,
  orderId,
  orderDetailLink,
  authorisePayment
}: {
  cost: CostInterface
  orderId: string
  orderDetailLink: string
  authorisePayment: () => void
}) {
  return (
    <motion.div
      animate={{ opacity: 1 }}
      className="flex h-full w-full flex-col items-center justify-center"
      initial={{ opacity: 0 }}
    >
      <WhitePanel className="max-w-5xl" heading="Authorise payment">
        <div className="flex flex-col space-y-6">
          <span>
            To ensure your payments are made safely and securely, we need you to authorise this payment with your bank.
          </span>
          <span>
            <a
              className="text-purple-500"
              href="https://stripe.com/gb/guides/strong-customer-authentication"
              rel="noopener noreferrer"
              target="_blank"
            >
              Find out more about secure payments
            </a>
          </span>
          <span className="flex flex-col space-y-3">
            <h3 className="text-lg">Payment details</h3>
            <span className="mt-4">
              Order ID:{' '}
              <Link className={`text-purple-500`} to={orderDetailLink}>
                {orderId}
              </Link>
            </span>
            <span>
              Amount:{' '}
              <strong>
                {formatCost({
                  amount: cost.amount,
                  currencyCode: cost.currencyCode
                })}
              </strong>
            </span>
          </span>
        </div>
        <div className="mt-10 flex w-full flex-col gap-3 sm:flex-row">
          <Button variant="primary" onClick={authorisePayment}>
            Authorise payment
          </Button>
          <Link className="btn btn-secondary" to={orderDetailLink}>
            I&apos;ll do it later
          </Link>
        </div>
      </WhitePanel>
    </motion.div>
  )
}

import toast from 'react-hot-toast'
import { motion } from 'motion/react'
import { useEffect, useState } from 'react'
import { GlobeAmericasIcon } from '@heroicons/react/24/outline'

import Button from '../../Button'
import TextField from '../../TextField'
import { createToast } from '../../Toast'
import SupportLink from '../../SupportLink'
import ClipboardCopy from '../../ClipboardCopy'
import OverlayPortal from '../../OverlayPortal'
import { IMAGE_LIBRARY_TOASTS } from '../constants'
import { IconCheckMark } from '../../InlineMenu/components'
import { ThumbnailPolling } from './ThumbnailPolling.component'
import { FetchErrorInterface, StatusType } from '../../../interfaces'
import { uploadURLToImageLibrary, getDefaultImageLibraryErrorMessage } from '../../../helpers'
import { ImageLibraryImageInterface, ImageLibraryImageType, useImageLibraryImages } from '../../../hooks'

interface UploadFromURLPropsInterface {
  isSaveToLibraryChecked: boolean
  type: ImageLibraryImageType
  onUploadStart: () => void
  onUploadComplete: () => void
  onSelectImage?: (imageLibraryImage: ImageLibraryImageInterface) => void
}

export function UploadFromURL({
  isSaveToLibraryChecked,
  type,
  onUploadStart,
  onUploadComplete,
  onSelectImage
}: UploadFromURLPropsInterface) {
  const { mutateImageLibraryImages } = useImageLibraryImages()

  const [urlValue, setUrlValue] = useState('')
  const [urlUploadStatus, setUrlUploadStatus] = useState<StatusType>('idle')
  const [pollForThumbnailImageId, setPollForThumbnailImageId] = useState<string | null>(null)

  useEffect(() => {
    return () => {
      toast.dismiss(IMAGE_LIBRARY_TOASTS.ERROR)
    }
  }, [])

  useEffect(() => {
    let timeoutId: NodeJS.Timeout
    if (urlUploadStatus === 'success') {
      timeoutId = setTimeout(() => {
        setUrlUploadStatus('idle')
      }, 1000)
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [urlUploadStatus])

  async function handleImageUploadSuccess(imageData: ImageLibraryImageInterface) {
    if (isSaveToLibraryChecked) {
      await mutateImageLibraryImages()
    }
    setUrlValue('')
    setUrlUploadStatus('success')
    onUploadComplete()

    if (onSelectImage) {
      onSelectImage(imageData)
    }
  }

  function handleImageUploadError({
    errorCode,
    errorMessage = null
  }: {
    errorCode: string | number
    errorMessage?: string | null
  }) {
    window.analytics.track('Image library - image upload failed', { type: 'url' })
    createToast({
      content: (
        <>
          {errorMessage && <p className="mb-2">{errorMessage}</p>}
          <p>
            Please try again later or <SupportLink>contact support</SupportLink> if the issue persists
          </p>
        </>
      ),
      duration: Infinity,
      footer: (
        <span className="text-sm text-gray-600">
          Code: {errorCode} <ClipboardCopy showText={false} text={errorCode.toString()} />
        </span>
      ),
      heading: 'Image upload failed',
      id: IMAGE_LIBRARY_TOASTS.ERROR,
      type: 'error-with-close'
    })
    setUrlUploadStatus('error')
    onUploadComplete()
  }

  async function handleURLUpload() {
    toast.dismiss(IMAGE_LIBRARY_TOASTS.ERROR)
    setUrlUploadStatus('loading')
    window.analytics.track('Image library - clicked: Upload an image', { type: 'url' })
    onUploadStart()
    try {
      const imageData = await uploadURLToImageLibrary({
        url: urlValue,
        shouldSaveToLibrary: isSaveToLibraryChecked,
        type
      })

      if (imageData.upload_status !== 'success') {
        setPollForThumbnailImageId(imageData.public_id)
        return
      }

      await handleImageUploadSuccess(imageData)
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<{ detail?: string; imageLibraryId?: string }>
      const errorMessage = getDefaultImageLibraryErrorMessage(errorResponse)
      const errorCode = `${errorResponse.status ?? '0'}-${errorResponse.responseBodyJson?.imageLibraryId ?? '0'}`
      handleImageUploadError({ errorCode, errorMessage })
    }
  }

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault()
        handleURLUpload()
      }}
    >
      <div className="flex gap-3">
        {/* TODO: This animation can be smoother with AnimatePresence's pop layout mode in Framer Motion v7+ (Needs React v18) */}
        <motion.div
          variants={{
            enter: {
              width: 0
            },
            visible: {
              width: 'auto'
            },
            exit: {
              width: 0,
              transition: { duration: 0.15 }
            }
          }}
          initial={'enter'}
          animate={'visible'}
          exit={'exit'}
        >
          <TextField
            className="mt-0 h-full sm:w-96 lg:w-[400px]"
            dataTest="image-library-url-upload-input"
            placeholder="URL"
            required
            type="url"
            value={urlValue}
            onChange={(event) => setUrlValue(event.target.value)}
          />
        </motion.div>

        <div>
          <Button
            dataTest="image-library-upload-from-url-button"
            disabled={urlUploadStatus === 'success'}
            isLoading={urlUploadStatus === 'loading'}
            startIcon={urlUploadStatus === 'success' ? undefined : <GlobeAmericasIcon className="h-7 w-7 text-white" />}
            className="whitespace-nowrap"
            type="submit"
          >
            <UploadButtonContent uploadStatus={urlUploadStatus} />
          </Button>
        </div>
      </div>

      {pollForThumbnailImageId && (
        <ThumbnailPolling
          imageLibraryId={pollForThumbnailImageId}
          onComplete={async ({ imageLibraryImage, imageLibraryImageFetchError }) => {
            setPollForThumbnailImageId(null)

            if (imageLibraryImageFetchError) {
              const errorMessage = getDefaultImageLibraryErrorMessage(imageLibraryImageFetchError)
              const errorCode = `P-${imageLibraryImageFetchError?.status ?? '0'}-${pollForThumbnailImageId}`
              handleImageUploadError({ errorCode, errorMessage })
              return
            }

            if (!imageLibraryImage || imageLibraryImage.upload_status !== 'success') {
              const errorCode = `P-${imageLibraryImage?.upload_status ?? 'P'}-${pollForThumbnailImageId}`
              handleImageUploadError({ errorCode })
              return
            }

            handleImageUploadSuccess(imageLibraryImage)
          }}
        />
      )}

      {urlUploadStatus === 'loading' && <OverlayPortal />}
    </form>
  )
}

function UploadButtonContent({ uploadStatus }: { uploadStatus: StatusType }) {
  switch (uploadStatus) {
    case 'loading':
      return <>Uploading...</>
    case 'success':
      return (
        <span className="flex items-center gap-2">
          <IconCheckMark isVisible={true} initial={true} transition={{ duration: 0.5 }} />
          Uploaded
        </span>
      )
    default:
      return (
        <>
          <span className="md:hidden">Upload</span>
          <span className="hidden md:inline">Upload from URL</span>
        </>
      )
  }
}

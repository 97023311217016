import clsx from 'clsx'
import { motion } from 'motion/react'
import { twMerge } from 'tailwind-merge'

interface ToggleGroupPropsInterface {
  className?: string
  disabled?: boolean
  layoutId: string
  theme?: 'standard' | 'muted'
  value: string
  viewTabs: Array<{ id: string; label: string }>
  onChange: (value: string) => void
}

export function ToggleGroup({
  className,
  disabled = false,
  layoutId,
  theme = 'standard',
  value,
  viewTabs,
  onChange
}: ToggleGroupPropsInterface) {
  return (
    <div className={twMerge('flex overflow-hidden', className)}>
      {viewTabs.map((tab) => (
        <button
          className={clsx(
            'text-inherit relative flex-1 border px-4 py-2 text-xs font-medium uppercase transition-colors',
            theme === 'standard' && 'border-white text-white',
            theme === 'muted' && 'border-gray-300 text-gray-300'
          )}
          disabled={disabled}
          key={tab.id}
          onClick={() => onChange(tab.id)}
        >
          {value === tab.id && (
            <motion.div
              className={clsx(
                'absolute inset-0 w-full',
                theme === 'standard' && 'bg-gray-50',
                theme === 'muted' && 'bg-gray-300'
              )}
              layoutId={layoutId}
              style={{ originY: '0px' }}
            />
          )}
          <span className="relative z-10 mix-blend-exclusion">{tab.label}</span>
        </button>
      ))}
    </div>
  )
}

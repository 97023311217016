import { PlusIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom'

import { ROUTE_PATHS } from '../../../constants'
import { OrdersExport } from './OrdersExport.component'

export function OrdersHeader() {
  return (
    <header className="flex items-center gap-3 md:items-start">
      <h1 className="mr-auto mt-0" data-test="orders-page-header">
        Orders
      </h1>

      <OrdersExport />

      <Link className="btn btn-primary md:m-win" to={ROUTE_PATHS.CREATE_ORDER}>
        <PlusIcon className="mr-2 hidden w-7 md:block" />
        Create order
      </Link>
    </header>
  )
}

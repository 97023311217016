import { useEffect } from 'react'
import { Redirect } from 'react-router-dom'

import { DEFAULT_DOCUMENT_TITLE, ROUTE_PATHS } from '../../constants'

export default function ProductInfoPage() {
  useEffect(() => {
    document.title = 'Product Info | Prodigi'
    return () => {
      document.title = DEFAULT_DOCUMENT_TITLE
    }
  }, [])

  return <Redirect to={ROUTE_PATHS.PRODUCT_CATALOGUE} />
}

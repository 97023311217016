import { useEffect } from 'react'
import { Redirect } from 'react-router-dom'

import { ANALYTICS_PAGE_CATEGORIES, DEFAULT_DOCUMENT_TITLE, ROUTE_PATHS } from '../../../constants'

export default function ProductCataloguePage() {
  useEffect(() => {
    document.title = `Products | Prodigi`
    window.analytics.page(ANALYTICS_PAGE_CATEGORIES.PRODUCT_CATALOGUE)
    return () => {
      document.title = DEFAULT_DOCUMENT_TITLE
    }
  })

  return <Redirect to={ROUTE_PATHS.PRODUCT_CATALOGUE} />
}

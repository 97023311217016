// @flow
export const FEATURE_NAMES = {
  TRANSORM_URLS: 'dashboard_transform_urls',
  SALES_CHANNELS_DELETE_ORDERS: 'sales_channels_delete_orders',
  IMAGE_LIBRARY_PDF: 'AllowPDFUploadsToImageLibrary',
  COSTED_ATTRIBUTES: 'dashboard_costed_attributes',
  SALES_CHANNEL_VOTE_FOR_PLATFORM: 'sales_channel_vote_for_platform',
  DASHBOARD_3D_MOCKUPS: 'dashboard_3d_mockups',
  PHOTOBOOK_METADATA: 'photobook_metadata',
  BRANDING_SETTINGS: 'dashboard_branding_settings',
  DASHBOARD_PRODIGI_PRO: 'dashboard_prodigi_pro',
  UNLESS_CHAT_BOT: 'dashboard_unless_chatbot',
  DIRECT_DEBIT: 'dashboard_direct_debit'
}

import { motion } from 'motion/react'
import { Dispatch, MouseEvent, ReactNode } from 'react'

import Button from '../../Button'
import RadioGroup from '../../RadioGroup'
import TextField from '../../TextField'
import { RegisterReducerActionTypeEnum } from '../enums'
import { RegisterFormStringFieldInterface } from '../interfaces'
import { RegisterReducerActionType } from '../types'

interface PropTypesInterface {
  autoFocusFormField: boolean
  fullName: RegisterFormStringFieldInterface
  companyName: RegisterFormStringFieldInterface
  children: ReactNode
  marketingWhyCreatingAccount: RegisterFormStringFieldInterface
  onStepChange: (event: MouseEvent<HTMLAnchorElement | HTMLButtonElement>, step: number) => void
  registerFormDispatch: Dispatch<RegisterReducerActionType>
}

const WHY_ARE_YOU_HERE_ANSWERS = [
  {
    value: `I'm starting a brand new business`,
    label: `I'm starting a brand new business`
  },
  {
    value: 'I want to grow my existing business',
    label: 'I want to grow my existing business'
  },
  {
    value: `I'm here to order a one-off product for myself, team or event`,
    label: `I'm here to order a one-off product for myself, team or event`
  },
  {
    value: `I'm onboarding with the Prodigi sales team`,
    label: `I'm onboarding with the Prodigi sales team`
  }
]

export default function Step2({
  autoFocusFormField,
  fullName,
  companyName,
  children,
  marketingWhyCreatingAccount,
  onStepChange,
  registerFormDispatch
}: PropTypesInterface) {
  return (
    <motion.form
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="flex min-h-full w-full flex-col"
    >
      <div className="flex flex-col">
        <span className="flex items-center justify-between">
          <label className="text-sm uppercase text-gray-600" htmlFor="name">
            Name
          </label>
        </span>
        <span className="relative">
          <TextField
            hasError={fullName.error !== null}
            dataTest="register-fullName"
            autoFocus={autoFocusFormField}
            value={fullName.value}
            onChange={(e) => {
              registerFormDispatch({
                type: RegisterReducerActionTypeEnum.SetFullName,
                payload: {
                  fullName: e.target.value,
                  validity: e.target.validity
                }
              })
            }}
            id="name"
            name="name"
            type="text"
            required
          />
        </span>

        <span className="mt-1 text-right text-sm text-red-500">
          <motion.span animate={fullName.error ? { opacity: 1 } : { opacity: 0 }} initial={{ opacity: 0 }}>
            {fullName.error}&nbsp;
          </motion.span>
        </span>

        <span className="flex items-center justify-between">
          <label className="text-sm uppercase text-gray-600" htmlFor="companyName">
            Company name (optional)
          </label>
        </span>

        <span className="relative">
          <TextField
            dataTest="register-companyName"
            value={companyName.value}
            onChange={(e) => {
              registerFormDispatch({
                type: RegisterReducerActionTypeEnum.SetCompanyName,
                payload: {
                  companyName: e.target.value
                }
              })
            }}
            id="companyName"
            name="companyName"
            type="text"
          />
        </span>

        <span className="mt-1 text-right text-sm text-red-500">
          <motion.span animate={companyName.error ? { opacity: 1 } : { opacity: 0 }} initial={{ opacity: 0 }}>
            {companyName.error}&nbsp;
          </motion.span>
        </span>

        <span className="flex items-center justify-between">
          <span className="text-sm uppercase text-gray-600">Why are you creating an account?</span>
        </span>

        <RadioGroup
          ariaLabel="Why are you creating an account?"
          className="mt-2"
          id="whyAreYouHere"
          items={WHY_ARE_YOU_HERE_ANSWERS}
          otherOptionConfig={{ placeholder: 'Tell us more...', maxLength: 100 }}
          selectedValue={marketingWhyCreatingAccount?.value}
          showOtherOptionInput
          onChange={(selection, error) => {
            registerFormDispatch({
              type: RegisterReducerActionTypeEnum.SetMarketingWhyCreatingAccount,
              payload: {
                marketingWhyCreatingAccount: selection,
                error
              }
            })
          }}
        />

        <span className="mt-1 text-right text-sm text-red-500">
          <motion.span
            animate={marketingWhyCreatingAccount.error ? { opacity: 1 } : { opacity: 0 }}
            initial={{ opacity: 0 }}
          >
            {marketingWhyCreatingAccount.error}&nbsp;
          </motion.span>
        </span>

        <Button
          disabled={Boolean(
            !fullName.value || companyName.error || !fullName.dirty || marketingWhyCreatingAccount.error
          )}
          type="submit"
          className="mt-12"
          onClick={(event) => {
            onStepChange(event, 3)
          }}
        >
          Continue
        </Button>

        {children}
      </div>
    </motion.form>
  )
}

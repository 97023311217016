import { ReactNode } from 'react'
import { motion } from 'motion/react'

import { cn } from '../../../helpers'

export function InsertsPanelItem({
  children,
  isSelected,
  onClick
}: {
  children: ReactNode
  isSelected: boolean
  onClick: () => void
}) {
  return (
    <li>
      <button
        className={cn(
          'relative flex w-fit items-center justify-center overflow-y-hidden px-6 pb-6 text-sm font-medium',
          isSelected ? 'text-purple-500' : 'text-gray-800'
        )}
        onClick={onClick}
      >
        <div>{children}</div>
        <div>
          {isSelected && (
            <motion.div
              initial={{ y: 5 }}
              animate={{ y: 0 }}
              exit={{ y: -5 }}
              className="absolute bottom-0 left-0 right-0 h-[4px] bg-purple-200"
              transition={{ type: 'spring', bounce: 0, duration: 0.4 }}
            />
          )}
        </div>
      </button>
    </li>
  )
}

import { motion } from 'motion/react'
import { Link } from 'react-router-dom'

import { ROUTE_PATHS } from 'src/v2/constants'

export function RecommendedProducts() {
  return (
    <div className="w-full pb-28">
      <div className="container mx-auto">
        <h2 className="mb-5 mt-8 text-black">Recommended products</h2>

        <p className="mb-16">Set your store up for success with our most-sold products.</p>

        <ul className="grid list-none grid-cols-2 gap-16 sm:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5">
          {APC_RECOMMENDED_PRODUCTS.map((apcRecommendedProduct) => (
            <ApcRecommendedProduct key={apcRecommendedProduct.imageUrl} apcRecommendedProduct={apcRecommendedProduct} />
          ))}
        </ul>
      </div>
    </div>
  )
}

type ApcRecommendedProductType = {
  name: string
  url: string
  imageUrl: string
}

const APC_RECOMMENDED_PRODUCTS: ApcRecommendedProductType[] = [
  {
    name: 'Acrylic prisms',
    url: `${ROUTE_PATHS.PRODUCT_CATALOGUE}/home-and-living/tabletops/acrylic-prism`,
    imageUrl: '/img/v2/recommended-products/acrylic-prism.webp'
  },
  {
    name: 'Classic frames',
    url: `${ROUTE_PATHS.PRODUCT_CATALOGUE}/wall-art/framed-prints/classic-frames`,
    imageUrl: '/img/v2/recommended-products/classic-frames.webp'
  },
  {
    name: 'Cushions',
    url: `${ROUTE_PATHS.PRODUCT_CATALOGUE}/home-and-living/cushions-and-pillows/cushions`,
    imageUrl: '/img/v2/recommended-products/cushions.webp'
  },
  {
    name: 'Fine art prints',
    url: `${ROUTE_PATHS.PRODUCT_CATALOGUE}/prints-and-posters/art-prints/enhanced-matte-art`,
    imageUrl: '/img/v2/recommended-products/enhanced-matte-art.webp'
  },
  {
    name: 'Framed canvas',
    url: `${ROUTE_PATHS.PRODUCT_CATALOGUE}/wall-art/canvas/framed-canvas/framed-canvas`,
    imageUrl: '/img/v2/recommended-products/classic-framed-canvas.webp'
  },
  {
    name: 'Photo mugs',
    url: `${ROUTE_PATHS.PRODUCT_CATALOGUE}/home-and-living/drinkware/photo-mugs`,
    imageUrl: '/img/v2/recommended-products/photo-mug.webp'
  },
  {
    name: 'Tough phone cases',
    url: `${ROUTE_PATHS.PRODUCT_CATALOGUE}/technology/phone-cases/tough-phone-case`,
    imageUrl: '/img/v2/recommended-products/tough-case.webp'
  },
  {
    name: 'Stretched canvas',
    url: `${ROUTE_PATHS.PRODUCT_CATALOGUE}/wall-art/canvas/stretched-canvas/stretched-canvas`,
    imageUrl: '/img/v2/recommended-products/stretched-canvas.webp'
  }
]

function ApcRecommendedProduct({ apcRecommendedProduct }: { apcRecommendedProduct: ApcRecommendedProductType }) {
  const { name, url, imageUrl } = apcRecommendedProduct

  return (
    <motion.li whileHover="hover" className="w-full">
      <Link
        className="flex w-full cursor-pointer flex-col items-center gap-2"
        to={url}
        onClick={() =>
          window.analytics.track('Clicked Recommended Product', {
            productName: name
          })
        }
      >
        <div className="aspect-square w-full overflow-hidden">
          <motion.img
            variants={{
              hover: {
                scale: 1.05
              }
            }}
            alt={name}
            src={imageUrl}
            className="w-full object-contain object-center"
          />
        </div>

        <span className="text-center text-black">{name}</span>
      </Link>
    </motion.li>
  )
}

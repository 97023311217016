import { useState } from 'react'
import * as DialogPrimitive from '@radix-ui/react-dialog'

import { cn } from '../../helpers'
import { PORTAL_ID } from '../../constants/portalId.const'
import ImageEditor, { ImageEditorPropsInterface } from '../ImageEditor'

export interface ImageEditorModalPropsInterface extends ImageEditorPropsInterface {
  open: boolean
  setOpen: (isOpen: boolean) => void
}

export default function ImageEditorModal({ open, setOpen, ...props }: ImageEditorModalPropsInterface) {
  const [triggerElement, setTriggerElement] = useState<Element | null>(null)

  return (
    <DialogPrimitive.Root open={open} onOpenChange={setOpen}>
      <DialogPrimitive.Portal container={document.getElementById(PORTAL_ID)}>
        <DialogPrimitive.Content
          aria-describedby={undefined}
          className={cn(
            'fixed inset-0 overflow-auto',
            'data-[state=closed]:duration-150 data-[state=open]:duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out data-[state=open]:fade-in data-[state=closed]:slide-out-to-bottom-2 data-[state=open]:slide-in-from-bottom-2'
          )}
          onOpenAutoFocus={() => {
            setTriggerElement(document.activeElement)
          }}
          onCloseAutoFocus={() => {
            // prettier-ignore
            (triggerElement as HTMLElement | null)?.focus?.()
          }}
          onEscapeKeyDown={(event) => event.preventDefault()}
          onInteractOutside={(event) => event.preventDefault()}
        >
          <DialogPrimitive.Title className="sr-only">Image Editor</DialogPrimitive.Title>
          <ImageEditor {...props} />
        </DialogPrimitive.Content>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  )
}

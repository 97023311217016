import { useLocation } from 'react-router'
import { useHotkeys } from '@mantine/hooks'
import * as Popover from '@radix-ui/react-popover'
import { AnimatePresence, motion } from 'motion/react'
import React, { useEffect, useMemo, useRef, useState } from 'react'

import {
  OrderFilterActiveCount,
  OrderFilterSelection,
  OrderFilterStatus,
  OrderFilterDate,
  OrderFilterSource
} from './components'
import { OrderFilterType } from './types'
import { QUERY_PARAMS } from '../../constants'
import { useMerchantService } from '../../hooks'
import { Bars3Icon } from '@heroicons/react/24/outline'
import { HOTKEYS } from '../../constants/hotkeys.const'
import { PORTAL_ID } from '../../constants/portalId.const'

export default function OrdersListActionFilter() {
  const { isLoading: isLoadingMerchantDetails, merchantDetailsFetchError } = useMerchantService()
  const isSalesChannelsAvailable = !isLoadingMerchantDetails && !merchantDetailsFetchError

  const [isOpen, setIsOpen] = useState(false)
  const popoverTriggerRef = useRef<HTMLButtonElement | null>(null)

  const { search } = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  const [openedFilter, setOpenedFilter] = useState<OrderFilterType>(null)
  const [selectedFilters, setSelectedFilters] = useState<OrderFilterType[]>([])

  useEffect(() => {
    const selectedFiltersFromQueryParams: OrderFilterType[] = []
    if (searchParams.get(QUERY_PARAMS.ORDERS.STATUS)) {
      selectedFiltersFromQueryParams.push('status')
    }
    if (searchParams.get(QUERY_PARAMS.ORDERS.START_DATE || QUERY_PARAMS.ORDERS.END_DATE)) {
      selectedFiltersFromQueryParams.push('date')
    }
    if (searchParams.get(QUERY_PARAMS.ORDERS.SOURCE)) {
      selectedFiltersFromQueryParams.push('source')
    }
    setSelectedFilters(selectedFiltersFromQueryParams)
  }, [searchParams])

  function handleHotKeyFilter(filter: NonNullable<OrderFilterType>) {
    setOpenedFilter(filter)
    if (!isOpen) {
      popoverTriggerRef.current?.click()
    }
  }

  useHotkeys([
    [HOTKEYS.ORDERS.STATUS.TRIGGER, () => handleHotKeyFilter('status')],
    [HOTKEYS.ORDERS.DATE.TRIGGER, () => handleHotKeyFilter('date')],
    [HOTKEYS.ORDERS.SOURCE.TRIGGER, () => isSalesChannelsAvailable && handleHotKeyFilter('source')]
  ])

  return (
    <Popover.Root
      onOpenChange={(open) => {
        setIsOpen(open)
        // Ensures we start with <OrderFilterSelection /> when re-opening the popover
        if (!open) {
          setOpenedFilter(null)
        }
      }}
    >
      <Popover.Trigger
        className="relative h-full w-full cursor-pointer truncate rounded-lg border border-gray-200 bg-white px-3 py-2 text-left outline-offset-1 outline-purple-500"
        data-test="orders-list-actions__filters"
        ref={popoverTriggerRef}
      >
        <span className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <Bars3Icon className="h-7 w-7 text-gray-500" aria-hidden="true" />
        </span>
        <span className="ml-9">Filter by</span>
        <OrderFilterActiveCount activeFilterCount={selectedFilters.length} />
      </Popover.Trigger>

      <AnimatePresence>
        {isOpen && (
          <Popover.Portal container={document.getElementById(PORTAL_ID)} forceMount>
            <Popover.Content asChild align="end" sideOffset={2}>
              <div style={{ perspective: '2000px', transformStyle: 'preserve-3d' }}>
                <motion.div
                  animate={{ opacity: 1, rotateX: '0deg' }}
                  className="max-w-screen min-w-[var(--prodigi-order-filters-min-width)] overflow-auto rounded-lg border bg-white text-input shadow-lg sm:min-w-[240px] sm:text-base"
                  exit={{ opacity: 0, rotateX: '-15deg' }}
                  initial={{ opacity: 0, rotateX: '-15deg' }}
                  style={
                    {
                      transformOrigin: 'center -45%',
                      transformStyle: 'preserve-3d',
                      '--prodigi-order-filters-min-width': popoverTriggerRef.current?.offsetWidth
                        ? `${popoverTriggerRef.current?.offsetWidth}px`
                        : '240px'
                    } as React.CSSProperties
                  }
                  transition={{ duration: 0.15 }}
                >
                  <AnimatePresence initial={false} mode="wait">
                    {!openedFilter && (
                      <OrderFilterSelection
                        isSalesChannelsAvailable={isSalesChannelsAvailable}
                        key="filters"
                        selectedFilters={selectedFilters}
                        onChangeFilter={setOpenedFilter}
                      />
                    )}
                    {openedFilter === 'status' && <OrderFilterStatus key="status" onChangeFilter={setOpenedFilter} />}
                    {openedFilter === 'date' && <OrderFilterDate key="date" onChangeFilter={setOpenedFilter} />}
                    {isSalesChannelsAvailable && openedFilter === 'source' && (
                      <OrderFilterSource key="source" onChangeFilter={setOpenedFilter} />
                    )}
                  </AnimatePresence>
                </motion.div>
              </div>
            </Popover.Content>
          </Popover.Portal>
        )}
      </AnimatePresence>
    </Popover.Root>
  )
}

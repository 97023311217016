import { useOs } from '@mantine/hooks'
import { motion } from 'motion/react'
import { ChevronRightIcon } from '@heroicons/react/24/outline'

import { HOTKEYS } from '../../../constants/hotkeys.const'
import { OnChangeFilterType, ImageLibraryFiltersType } from '../types'
import InlineMenu, { InlineMenuOptionInterface } from '../../InlineMenu'

export function ImageLibraryFilterSelection({
  onChangeFilter,
  selectedFilters
}: {
  onChangeFilter: OnChangeFilterType
  selectedFilters: ImageLibraryFiltersType[]
}) {
  const OS = useOs()
  const isAppleOs = OS === 'macos' || OS === 'ios'
  const filterOptions: Array<InlineMenuOptionInterface> = [
    {
      content: <ImageLibraryFilterItem label="Tagged" />,
      value: 'tag',
      searchLabel: 'tag',
      hint: isAppleOs ? HOTKEYS.IMAGE_LIBRARY.TAG.DISPLAY.APPLE : HOTKEYS.IMAGE_LIBRARY.TAG.DISPLAY.DEFAULT
    },
    {
      content: <ImageLibraryFilterItem label="Type" />,
      value: 'type',
      searchLabel: 'type',
      hint: isAppleOs ? HOTKEYS.IMAGE_LIBRARY.TYPE.DISPLAY.APPLE : HOTKEYS.IMAGE_LIBRARY.TYPE.DISPLAY.DEFAULT
    },
    {
      content: <ImageLibraryFilterItem label="File format" />,
      value: 'file-format',
      searchLabel: 'file-format',
      hint: isAppleOs
        ? HOTKEYS.IMAGE_LIBRARY.FILE_FORMAT.DISPLAY.APPLE
        : HOTKEYS.IMAGE_LIBRARY.FILE_FORMAT.DISPLAY.DEFAULT
    },
    {
      content: <ImageLibraryFilterItem label="Orientation" />,
      value: 'orientation',
      searchLabel: 'orientation',
      hint: isAppleOs
        ? HOTKEYS.IMAGE_LIBRARY.ORIENTATION.DISPLAY.APPLE
        : HOTKEYS.IMAGE_LIBRARY.ORIENTATION.DISPLAY.DEFAULT
    }
  ]

  return (
    <motion.div
      animate={{ opacity: 1, x: 0 }}
      data-test="image-library-filters__filter-menu"
      exit={{ opacity: 0, x: -5 }}
      initial={{ opacity: 0, x: -5 }}
      key="filters"
      transition={{ type: 'tween', duration: 0.1 }}
    >
      <InlineMenu
        ariaLabel="Image Library Filters"
        focusOnMount
        multiple
        options={filterOptions}
        selected={selectedFilters as string[]}
        onChange={(value) => {
          onChangeFilter(value as ImageLibraryFiltersType)
        }}
      />
    </motion.div>
  )
}

function ImageLibraryFilterItem({ label }: { label: string }) {
  return (
    <span className="flex w-full items-center justify-between">
      <span>{label}</span>
      <ChevronRightIcon className="mx-6 w-4 flex-shrink-0 text-gray-600" />
    </span>
  )
}

import { AnimatePresence, motion, Transition } from 'motion/react'

interface IconCheckMarkPropsInterface {
  className?: string
  dataTest?: string
  initial?: boolean
  isVisible: boolean
  style?: React.CSSProperties
  transition?: Transition
}

export default function AnimatedCheckIcon({
  className = 'h-6 w-6',
  dataTest,
  initial = false,
  isVisible,
  style,
  transition
}: IconCheckMarkPropsInterface) {
  return (
    <AnimatePresence initial={initial}>
      {isVisible && (
        <svg
          className={`${className}`}
          data-test={dataTest}
          fill="none"
          stroke="currentColor"
          strokeWidth={2}
          style={style}
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <motion.path
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 1 }}
            exit={{ pathLength: 0 }}
            transition={{
              bounce: 0,
              duration: 0.2,
              ...transition
            }}
            d="M5 13l4 4L19 7"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </AnimatePresence>
  )
}

import { useState } from 'react'
import { AnimatePresence, motion } from 'motion/react'

import ClipboardCopy from '../../ClipboardCopy'
import { OrderDetailEditAddressModal } from './OrderDetailEditAddressModal.component'
import { getCountryName } from '../../../../helpers/countryNames'
import { OrderDetailRecipientInterface } from '../../../interfaces'

interface OrderDetailAddressPropsInterface {
  orderId: string
  orderRecipient: OrderDetailRecipientInterface
  showEditButton: boolean
  mutateOrderDetails: () => void
}

export function OrderDetailAddress({
  orderId,
  orderRecipient,
  showEditButton,
  mutateOrderDetails
}: OrderDetailAddressPropsInterface) {
  const [isEditAddressModalOpen, setIsEditAddressModalOpen] = useState(false)

  return (
    <div className="isolate w-full border bg-white p-6" data-test="order-detail-address">
      <div>
        <span className="flex min-h-[31px] w-full flex-wrap items-center">
          <h2 className="mt-0 text-lg">Address</h2>

          <AnimatePresence>
            {showEditButton && (
              <motion.button
                animate={{ opacity: 1 }}
                className="btn btn-secondary btn-sm ml-auto"
                exit={{ opacity: 0 }}
                initial={{ opacity: 0 }}
                onClick={() => {
                  window.analytics.track('Clicked Edit Address', { orderId })
                  setIsEditAddressModalOpen(true)
                }}
              >
                <div>
                  Edit <span className="hidden sm:inline">address</span>
                </div>
              </motion.button>
            )}
          </AnimatePresence>
        </span>

        <address className="mt-4 flex flex-col">
          <span data-test="order-detail-address__recipient-name">{orderRecipient.name}</span>
          <span data-test="order-detail-address__line1">{orderRecipient.address.line1}</span>
          <span data-test="order-detail-address__line2">{orderRecipient.address.line2}</span>
          <span data-test="order-detail-address__town">{orderRecipient.address.townOrCity}</span>
          <span data-test="order-detail-address__state">{orderRecipient.address.stateOrCounty}</span>
          <span className="uppercase" data-test="order-detail-address__postcode">
            {orderRecipient.address.postcodeOrZipCode}
          </span>
          <span data-test="order-detail-address__country">{getCountryName(orderRecipient.address.countryCode)}</span>
          {orderRecipient.phone.length > 0 && (
            <span className="mt-8">
              t.{' '}
              <a
                className="text-purple-500 underline-offset-1 hover:underline"
                data-test="order-detail-address__phone"
                href={`tel:${orderRecipient.phone}`}
              >
                {orderRecipient.phone}
              </a>
            </span>
          )}
          {orderRecipient.email.length > 0 && (
            <span
              className={`inline-flex items-center break-all ${orderRecipient.phone.length > 0 ? '' : 'mt-8'}`}
              data-test="order-detail-address__email"
            >
              <span>
                e.{' '}
                <a
                  className="text-purple-500 underline-offset-1 hover:underline"
                  href={`mailto:${orderRecipient.email}`}
                  rel="noopener noreferrer"
                  title="Email customer"
                >
                  {orderRecipient.email}
                </a>
              </span>
              <ClipboardCopy text={orderRecipient.email} showText={false} />
            </span>
          )}
        </address>
      </div>

      <OrderDetailEditAddressModal
        open={isEditAddressModalOpen}
        orderId={orderId}
        orderRecipient={orderRecipient}
        mutateOrderDetails={mutateOrderDetails}
        setOpen={setIsEditAddressModalOpen}
      />
    </div>
  )
}

// @flow
import React, { Component } from 'react'
import ManualOrderForm from '../pages/ManualOrderForm'
import PageNotFound from './PageNotFound'
import { Router, Route, Switch } from 'react-router-dom'
import { StyleRoot } from 'radium'
import ErrorBoundary from './ErrorBoundary'
import AuthenticatedRouteGuard from './AuthenticatedRouteGuard'
import AppUser from './AppUser'
import ScrollToTop from './ScrollToTop'
import LogOutErrorModal from '../components/LogOutErrorModal'
import CsvUpload from '../pages/CsvUpload'
import Apps from '../pages/Apps'
import MofImageEditor from '../pages/ManualOrderForm/MofImageEditor'
import AddNewShop from '../pages/AddNewShop'
import ConfigureApp from '../pages/ConfigureApp'
import type { RouterHistory } from 'react-router'
import SplitWrapper from './SplitWrapper/SplitWrapper'
import { Toaster } from 'react-hot-toast'
import { MotionConfig } from 'motion/react'
import { SplitClient } from '@splitsoftware/splitio-react'
import { getAnonymousId } from '../helpers/getAnonymousId'

// $FlowFixMe: TypeScript component
import OrdersPage from '../v2/pages/orders'
// $FlowFixMe: TypeScript component
import OrderDetailPage from '../v2/pages/orders/[orderId].tsx'
// $FlowFixMe: TypeScript file
import { PORTAL_ID } from '../v2/constants/portalId.const'
// $FlowFixMe: TypeScript file
import { PRODIGI_TOAST_CLASSNAME } from '../v2/constants/prodigiToast.const'
// $FlowFixMe: TypeScript component
import SalesChannelsPage from '../v2/pages/sales-channels'
// $FlowFixMe: TypeScript component
import ConnectSalesChannelFromRedirectPage from '../v2/pages/connect-sales-channel'
// $FlowFixMe: TypeScript component
import MaintainSalesChannelConnectionsPage from '../v2/pages/maintain-sales-channel'
// $FlowFixMe: TypeScript component
import SalesChannelsConfigurePage from '../v2/pages/sales-channels/[salesChannelId]/index'
// $FlowFixMe: TypeScript component
import SalesChannelProductPage from '../v2/pages/sales-channels/[salesChannelId]/[productId]'
// $FlowFixMe: TypeScript component
import PaymentAuthPage from 'src/v2/pages/payment-authorisation'
// $FlowFixMe: TypeScript component
import ProductInfo from 'src/v2/pages/product-info'
// $FlowFixMe: TypeScript component
import ProductCatalogue from 'src/v2/pages/product-info/products'
// $FlowFixMe: TypeScript component
import ProductCategory from 'src/v2/pages/product-info/products/[productCategory].tsx'
// $FlowFixMe: TypeScript component
import DownloadPage from 'src/v2/pages/download'
// $FlowFixMe: TypeScript component
import UserSetup from 'src/v2/components/UserSetup'
// $FlowFixMe: TypeScript file
import { generateUserId } from '../v2/helpers'
// $FlowFixMe: TypeScript component
import SettingsPage from '../v2/pages/settings'
// $FlowFixMe: TypeScript file
import DashboardPage from 'src/v2/pages/dashboard'
// $FlowFixMe: TypeScript file
import HomePage from '../v2/pages/index'
// $FlowFixMe: TypeScript component
import RegisterPage from '../v2/pages/register'
// $FlowFixMe: TypeScript component
import LoginPage from '../v2/pages/login'
// $FlowFixMe: TypeScript component
import PasswordResetPage from '../v2/pages/password-reset'
// $FlowFixMe: TypeScript component
import ChangePasswordPage from '../v2/pages/password-change'
// $FlowFixMe: TypeScript component
import ResetPasswordCallbackPage from '../v2/pages/account/resetpassword'
// $FlowFixMe: TypeScript component
import SettingsApiPage from '../v2/pages/settings/api'
// $FlowFixMe: TypeScript component
import SettingsAccountPage from '../v2/pages/settings/account'
// $FlowFixMe: TypeScript component
import SettingsBillingPage from '../v2/pages/settings/billing'
// $FlowFixMe: TypeScript component
import SettingsBrandingPage from '../v2/pages/settings/branding'
// $FlowFixMe: TypeScript component
import SettingsIntegrationsPage from '../v2/pages/settings/integrations'
// $FlowFixMe: TypeScript component
import SettingsPreferencesPage from '../v2/pages/settings/preferences'
// $FlowFixMe: TypeScript file
import ProductSkuDetailPage from '../v2/pages/products/[sku]'
// $FlowFixMe: TypeScript component
import Navigation from '../v2/components/Navigation'
// $FlowFixMe: TypeScript component
import ImageLibraryPage from '../v2/pages/image-library'
// $FlowFixMe: TypeScript component
import ImageDetailPage from '../v2/pages/image-library/[imageId]'
// $FlowFixMe: TypeScript component
import BrowseProductPage from '../v2/pages/product-catalogue'
// $FlowFixMe: TypeScript component
import PricingPage from '../v2/pages/pricing'

// $FlowFixMe: TypeScript file
import { APP_ENVIRONMENTS, ROUTE_PATHS } from '../v2/constants'

type Props = {|
  history: RouterHistory
|}

type State = {|
  merchantId: ?string,
  authorisedRoutesSplitKey: string
|}

export default class AsyncApp extends Component<Props, State> {
  state: State = {
    merchantId: null,
    authorisedRoutesSplitKey: getAnonymousId()
  }

  handleAppUserMount: (merchantId: string) => void = (merchantId: string) => {
    if (merchantId === this.state.merchantId) {
      return
    }
    window.clarity?.('set', 'merchantId', merchantId)
    this.setState({ merchantId, authorisedRoutesSplitKey: generateUserId(merchantId) })
  }

  render(): React$Node {
    return (
      <StyleRoot
        style={{
          minHeight: '100%',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          backgroundColor: 'var(--color-grey-10)'
        }}
      >
        <Toaster position="top-right" toastOptions={{ className: PRODIGI_TOAST_CLASSNAME }} />
        <Router history={this.props.history}>
          <ErrorBoundary>
            <SplitWrapper>
              <MotionConfig reducedMotion="user">
                <ScrollToTop />
                <LogOutErrorModal />

                <PageNotFound>
                  <div>
                    <Switch>
                      <Route exact path={ROUTE_PATHS.LOGIN} component={LoginPage} />
                      <Route exact path="/password-change" component={ChangePasswordPage} />
                      <Route exact path="/account/resetpassword" component={ResetPasswordCallbackPage} />
                      <Route path="/register/:stepNumber?" component={RegisterPage} />
                      <Route path={ROUTE_PATHS.PASSWORD_RESET} component={PasswordResetPage} />
                      <Route exact path="/" component={HomePage} />
                    </Switch>
                  </div>

                  <AuthenticatedRouteGuard redirectPath={ROUTE_PATHS.LOGIN}>
                    <SplitClient
                      splitKey={this.state.authorisedRoutesSplitKey}
                      trafficType="user"
                      updateOnSdkTimedout={true}
                    >
                      <UserSetup>
                        <AppUser onAppUserMount={this.handleAppUserMount}>
                          <Navigation>
                            <Route path="/products/:sku" component={ProductSkuDetailPage} exact />

                            {/* v2 payment auth page */}
                            <Route path={ROUTE_PATHS.PAYMENT_AUTHORISATION} exact component={PaymentAuthPage} />

                            <Route path={ROUTE_PATHS.SETTINGS.INDEX} component={SettingsPage} />
                            {process.env.REACT_APP_ENV !== APP_ENVIRONMENTS.SANDBOX && (
                              <Route path={ROUTE_PATHS.SETTINGS.ACCOUNT} exact component={SettingsAccountPage} />
                            )}
                            {process.env.REACT_APP_ENV !== APP_ENVIRONMENTS.SANDBOX && (
                              <Route path={ROUTE_PATHS.SETTINGS.BILLING} exact component={SettingsBillingPage} />
                            )}
                            <Route
                              path={ROUTE_PATHS.SETTINGS.INTEGRATIONS}
                              exact
                              component={SettingsIntegrationsPage}
                            />
                            <Route path={ROUTE_PATHS.SETTINGS.PREFERENCES} exact component={SettingsPreferencesPage} />
                            <Route path={ROUTE_PATHS.SETTINGS.API} exact component={SettingsApiPage} />
                            <Route path={ROUTE_PATHS.SETTINGS.BRANDING} exact component={SettingsBrandingPage} />
                            <Route path={ROUTE_PATHS.PRODUCT_INFO} component={ProductInfo} exact />
                            <Route path={ROUTE_PATHS.PRODUCTS} component={ProductCatalogue} exact />
                            <Route path="/product-info/products/:category" component={ProductCategory} />
                            <Route path={`${ROUTE_PATHS.PRODUCT_CATALOGUE}`} component={BrowseProductPage} exact />
                            <Route
                              path={`${ROUTE_PATHS.PRODUCT_CATALOGUE}/:categorySlug`}
                              component={BrowseProductPage}
                              exact
                            />
                            <Route
                              path={`${ROUTE_PATHS.PRODUCT_CATALOGUE}/:categorySlug/:subCategorySlug`}
                              component={BrowseProductPage}
                              exact
                            />
                            <Route
                              path={`${ROUTE_PATHS.PRODUCT_CATALOGUE}/:categorySlug/:subCategorySlug/:subSubCategorySlug`}
                              component={BrowseProductPage}
                              exact
                            />
                            <Route
                              path={`${ROUTE_PATHS.PRODUCT_CATALOGUE}/:categorySlug/:subCategorySlug/:subSubCategorySlug/:productSlug`}
                              component={BrowseProductPage}
                              exact
                            />
                            <Route path={ROUTE_PATHS.PRICING} component={PricingPage} exact />
                            <Route path={ROUTE_PATHS.ORDERS} component={OrdersPage} exact />
                            <Route path="/orders/create" component={ManualOrderForm} />
                            <Route path="/orders/:id/detail" component={OrderDetailPage} exact />
                            <Route path={ROUTE_PATHS.BULK_UPLOAD} component={CsvUpload} exact />
                            <Route path={ROUTE_PATHS.DASHBOARD} component={DashboardPage} />
                            <Route path="/apps" component={Apps} exact />
                            <Route path="/apps/connect/:appType" component={AddNewShop} />
                            <Route path="/apps/configure/:appType/:appId" component={ConfigureApp} />
                            <Route path="/image-editor/:basketItemId" component={MofImageEditor} exact />
                            <Route path="/download" component={DownloadPage} exact />
                            <Route path={ROUTE_PATHS.SALES_CHANNELS.INDEX} component={SalesChannelsPage} exact />
                            <Route
                              path={ROUTE_PATHS.SALES_CHANNELS.CONNECT_FROM_REDIRECT}
                              component={ConnectSalesChannelFromRedirectPage}
                              exact
                            />
                            <Route
                              path={ROUTE_PATHS.MAINTAIN_SALES_CHANNEL_CONNECTIONS}
                              component={MaintainSalesChannelConnectionsPage}
                              exact
                            />
                            <Route
                              path={`${ROUTE_PATHS.SALES_CHANNELS.INDEX}/:salesChannelId`}
                              component={SalesChannelsConfigurePage}
                              exact
                            />
                            <Route
                              path={`${ROUTE_PATHS.SALES_CHANNELS.INDEX}/:salesChannelId/:productId`}
                              component={SalesChannelProductPage}
                              exact
                            />
                            <Route path={ROUTE_PATHS.IMAGE_LIBRARY.INDEX} component={ImageLibraryPage} exact />
                            <Route path={`${ROUTE_PATHS.IMAGE_LIBRARY.INDEX}/:id`} component={ImageDetailPage} exact />
                          </Navigation>
                        </AppUser>
                      </UserSetup>
                    </SplitClient>
                  </AuthenticatedRouteGuard>
                </PageNotFound>
              </MotionConfig>
            </SplitWrapper>
            {/* Highest zIndex configured for tailwind: 50 */}
            <div id={PORTAL_ID} className="tailwind" style={{ zIndex: 50 }}></div>
          </ErrorBoundary>
        </Router>
      </StyleRoot>
    )
  }
}

import { useState } from 'react'
import toast from 'react-hot-toast'
import { motion } from 'motion/react'
import { useDispatch } from 'react-redux'

import { useUser } from 'src/v2/hooks'
import { StatusEnum } from 'src/v2/enums'
import Button from 'src/v2/components/Button'
import { EXTERNAL_URLS } from 'src/v2/constants'
import TextField from 'src/v2/components/TextField'
import { updateUserSuccess } from 'src/actions/user'
import { createToast } from 'src/v2/components/Toast'
import { SettingsPanel } from '../../../SettingsPanel.component'
import { FetchErrorInterface, UserInterface } from 'src/v2/interfaces'
import { updateUserSettings } from 'src/v2/components/Settings/helpers'
import { SETTINGS_UPDATE_ERROR_TOAST_ID, SETTINGS_UPDATE_SUCCESS_TOAST_ID } from 'src/v2/components/Settings/constants'

function WebhooksPanel({ className = '' }: { className?: string }) {
  const dispatch = useDispatch()
  const { user, mutateUser } = useUser()

  const [callbacksPanelStatus, setCallbacksPanelStatus] = useState(StatusEnum.Idle)
  const [webhookUrl, setWebhookUrl] = useState(user?.settings.callbackUrl ?? '')

  async function handleWebhooksUpdate() {
    try {
      if (!user) {
        createToast({
          content: `Please try again later`,
          footer: 'Code: NUE',
          heading: 'Failed to update',
          id: SETTINGS_UPDATE_ERROR_TOAST_ID,
          type: 'error-with-close'
        })
        return
      }

      toast.dismiss(SETTINGS_UPDATE_SUCCESS_TOAST_ID)
      toast.dismiss(SETTINGS_UPDATE_ERROR_TOAST_ID)

      setCallbacksPanelStatus(StatusEnum.Loading)

      const newUserSettings: UserInterface = {
        ...user,
        settings: {
          ...user.settings,
          callbackUrl: webhookUrl
        }
      }

      await updateUserSettings(newUserSettings)
      await mutateUser()

      createToast({ heading: 'Updated successfully', id: SETTINGS_UPDATE_SUCCESS_TOAST_ID, type: 'success' })
      setCallbacksPanelStatus(StatusEnum.Success)

      // TODO: v2: Remove this dispatch when we no longer need v1 user details in Redux
      dispatch(updateUserSuccess())
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<unknown>
      createToast({
        content: `Please try again later (${errorResponse.status ?? errorResponse.name})`,
        duration: Infinity,
        heading: 'Failed to update',
        id: SETTINGS_UPDATE_ERROR_TOAST_ID,
        type: 'error-with-close'
      })
      setCallbacksPanelStatus(StatusEnum.Error)
    }
  }

  return (
    <SettingsPanel
      className={className}
      title={<h2>Webhooks</h2>}
      subtitle={
        <>
          <span className="mt-4 text-gray-600">Sent for all order status changes.</span>
          <a
            className="w-fit text-purple-500"
            href={EXTERNAL_URLS.PRODIGI.VIEW_SAMPLE_WEBHOOK.URL}
            rel="noreferrer noopener"
            target="_blank"
          >
            View a sample hook payload.
          </a>
        </>
      }
    >
      <form
        className="max-w-xl"
        onSubmit={(event) => {
          event.preventDefault()
          handleWebhooksUpdate()
        }}
      >
        <label className="text-gray-600">Webhook URL</label>
        <TextField onChange={(e) => setWebhookUrl(e.target.value)} value={webhookUrl} placeholder="https://" />
        <span className="mt-1 text-right text-sm text-red-500">
          <motion.span
            animate={callbacksPanelStatus === StatusEnum.Error ? { opacity: 1 } : { opacity: 0 }}
            initial={{ opacity: 0 }}
          >
            Failed to update webhook URL
          </motion.span>
        </span>
        <Button
          disabled={callbacksPanelStatus === StatusEnum.Loading}
          isLoading={callbacksPanelStatus === StatusEnum.Loading}
          className="mt-4"
          type="submit"
          variant="primary"
        >
          Update
        </Button>
      </form>
    </SettingsPanel>
  )
}

export { WebhooksPanel }
